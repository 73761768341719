import lwAjax from 'utilities/lwAjax';
import { IgnoredProperties } from 'utilities/constants';

export default function QuizPreferenceService(initialPreferenceList) {

  function saveData(prePreferenceList, quizId) {
    const preferencesToSubmit = prePreferenceList.filter(isNewModifiedOrMarkedForDelete);
    const promises = preferencesToSubmit.map((preference) => {
      const action = isNew(preference) ? 'POST' : preference.markedForDelete ? 'DELETE' : 'PUT';
      const body = JSON.stringify({preference: preference});
      const preferenceId = preference.id || '';
      return lwAjax(action, `/quizzes/${quizId}/quiz_preferences/${preferenceId}`, handleCreateOrUpdate, body);
    });
    return Promise.all(promises);
  }

  // ------------------------ PRIVATE START ------------------------
  function handleCreateOrUpdate(data) {
    // handle resolution logic in caller
    return data;
  }

  function isNewModifiedOrMarkedForDelete(preference) {
    return isNew(preference) || preference.markedForDelete || isPreferenceModified(preference);
  }

  function isNew(preference) { return !preference.id; }

  function isPreferenceModified(preference) {
    const targetPreferenceInitialValue = initialPreferenceList.find(p => p.id === preference.id);

    // Iterate over every key/value pair in the initial copy of the preference stored when the service initialized
    // and compare them with the key/value pairs in the passed in preference at the time of submit.
    // Return true if not every one is equal.
    return !Object.entries(targetPreferenceInitialValue).every(([preferenceProperty, initialValue]) => {
      if (IgnoredProperties.includes(preferenceProperty)) { return true; }
      return preference[preferenceProperty] === initialValue;
    });
  }
  // ------------------------ PRIVATE END ------------------------

  return {
    saveData
  }
}
