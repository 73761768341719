export default function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  if (minutes > 0) {
    const remainderSeconds = seconds - (minutes * 60);
    const paddedSeconds = remainderSeconds < 10 ? `0${remainderSeconds}` : remainderSeconds;
    return `${minutes}:${paddedSeconds}`;
  } else {
    const paddedSeconds = seconds < 10 ? `0${seconds}` : seconds;
    return `0:${paddedSeconds}`;
  }
}