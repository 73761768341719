import React, { useState } from 'react';
import AdminMenu from './AdminMenu';
import lwAjax from 'utilities/lwAjax';

function QuizIndex(props) {
  const {
    quizzes
  } = props;

  // This is just so we can update the view without having to make another ajax call after a
  // quiz is successfully deleted.
  const [localQuizzes, setLocalQuizzes] = useState(quizzes);

  function editQuiz(e) {
    window.open(`/quizzes/${parseQuizId(e.target.id)}/edit`, "_blank");
  }

  function runQuiz(e) {
    window.open(`/${parseQuizId(e.target.id)}/run`, "_blank");
  }

  function deleteQuiz(e) {
    const confirmDelete = confirm("Are you sure you want to irreversibly delete this quiz?");
    if (confirmDelete) {
      lwAjax('DELETE', `/quizzes/${parseQuizId(e.target.id)}`, handleQuizDeleteSuccess).catch((error) => {
        console.log("error --> ", error);
        alert("There was an error deleting your quiz.");
      });
    }
  }

  function handleQuizDeleteSuccess(data) {
    const filteredQuizzes = localQuizzes.filter(q => q.id !== data.result.id);
    setLocalQuizzes(filteredQuizzes);
    alert("Your quiz was successfully deleted.");
  }

  function parseQuizId(id) {
    return id.match(/\d+/)[0];
  }

  function navigateToNew() {
    window.open("/quizzes/new", "_blank");
  }

  function confirmSignout() {
    const confirmSignout = confirm("Are you sure you want to signout?");
    if (confirmSignout) {
      window.location.href = "/admins/sign_out";
    }
  }

  return (
    <div className="quiz-builder-index">
      <div className="quiz-list">
        <div className="quiz-list-header">
          <div className="quiz-list-title">
            Available Quizzes:
          </div>
          <div className="header-actions">
            <button onClick={navigateToNew}>
              New Quiz
            </button>
            <button onClick={confirmSignout}>
              Sign Out
            </button>
          </div>
        </div>
      { localQuizzes.length > 0 ? (
        <fieldset className="quiz-selector">
          <legend>Run, Edit, or Delete a Quiz</legend>
        { localQuizzes.map((quiz) =>
          <div key={quiz.id} className="quiz-action-container" >
            <div>
              <span className="quiz-id">{quiz.id}</span>
              {quiz.name}
            </div>
            <button id={`run_${quiz.obfuscated_id}`} onClick={runQuiz}>
              Run
            </button>
            <button id={`edit_${quiz.id}`} onClick={editQuiz}>
              Edit
            </button>
            <button id={`delete_${quiz.id}`} onClick={deleteQuiz}>
              Delete
            </button>
          </div>
        )}
        </fieldset>
      ) : (
        <fieldset className="no-quizzes-msg">
          <legend>New Account</legend>
          <div>
            No Quizzes to display
            <span className="detail-hint"> – Create your first quiz with the <strong>New Quiz</strong> button in the header.</span>
          </div>
        </fieldset>
      )}
      </div>
      <AdminMenu
        page='QuizIndex'
        quizzes={localQuizzes}
      />
    </div>
  );
}

export default QuizIndex
