import React, { useState } from 'react';
import AdminMenu from '../AdminMenu';
import QuizPreview from './QuizPreview';
import QuizInputs from './QuizInputs';
import QuizQuestionInputs from './QuizQuestionInputs';
import QuizPreferenceInputs from './QuizPreferenceInputs';
import QuizPlayerLevelInputs from './QuizPlayerLevelInputs';
import QuizSurveyQuestionInputs from './QuizSurveyQuestionInputs';
import CustomFinalResultsInputs from './CustomFinalResultsInputs';
import CustomGetStartedInputs from './CustomGetStartedInputs';
import ImageUploader from './ImageUploader';
import CssUploader from './CssUploader';
import useLwAjax from 'hooks/useLwAjax';
import lwAjax from 'utilities/lwAjax';
import QuizSurveyQuestionAndAnswerService from 'utilities/builder_data_utils/QuizSurveyQuestionAndAnswerService';
import QuizPreferenceService from 'utilities/builder_data_utils/QuizPreferenceService';
import QuizQuestionsAndOptionsService from 'utilities/builder_data_utils/QuizQuestionsAndOptionsService';
import QuizService from '../../utilities/builder_data_utils/QuizService';
import { NullableQuizFields } from '../../utilities/constants';
import CustomFinalResultsHtmlGenerator from '../../utilities/CustomFinalResultsHtmlGenerator';
import CustomGetStartedHtmlGenerator from "../../utilities/CustomGetStartedHtmlGenerator";
import SharedMetadataInputs from "./SharedMetadataInputs";
import cloneDeep from 'lodash/cloneDeep';


function QuizBuilder(props) {
  const {
    quiz,
    quizQuestions,
    preferences,
    surveyQuestions
  } = props;

  const [visibleInputs, setVisibleInputs] = useState({
    questionInputs: false,
    preferenceInputs: false,
    playerLevelInputs: false,
    quizSurveyQuestionInputs: false,
    customFinalResultsScreenInputs: false,
    customGetStartedScreenInputs: false,
    imageUploadInputs: false,
    cssUploadInputs: false
  });

  const defaultQuizQuestionAndOptionData = {
    quiz_id: quiz.id,
    type: 'StandardQuiz',
    text: '',
    footnote: '',
    correct_answer_explanation: '',
    incorrect_answer_explanation: '',
    custom_final_results_html: '',
    custom_get_started_html: '',
    options: [
      {index: 0, is_correct: true, text: ''},
      {index: 1, is_correct: false, text: ''}
    ]
  }
  const defaultSurveyQuestionData = {answers: []};
  // All current<AssociationName> constants are temporary containers that are passed to their
  // associated input components for modification.  When the submit buttons below those components are hit, the
  // data stored in these variables are either added to or removed from the <associationName>List constants (if new),
  // or modified in place if they have been persisted to the database (if they have an id property).
  const [currentQuizQuestionAndOptionData, setCurrentQuizQuestionAndOptionData] = useState(defaultQuizQuestionAndOptionData);
  const [currentSurveyQuestionData, setCurrentSurveyQuestionData] = useState(defaultSurveyQuestionData);
  const [currentPreference, setCurrentPreference] = useState({});
  const [currentPlayerLevel, setCurrentPlayerLevel] = useState({});
  const [currentCustomFinalResultsHtml, setCurrentCustomFinalResultsHtml] = useState(() => {
    return quiz.custom_final_results_html || new CustomFinalResultsHtmlGenerator('default').generate();
  });
  const [currentCustomGetStartedHtml, setCurrentCustomGetStartedHtml] = useState(() => {
    return quiz.custom_get_started_html || new CustomGetStartedHtmlGenerator('default').generate();
  });
  const [currentSharedMetadata, setCurrentSharedMetadata] = useState(() =>{
    return cloneDeep(quiz.shared_metadata) || {facebook: {}, twitter: {}}
  });

  // This is a special case, and is both a store of the currently editable data,
  // and the values that will be submitted on "Save Quiz"
  const [quizData, setQuizData] = useState(quiz);

  // Passed to QuizPreview.  These contain the data that will be submitted on "Save Quiz"
  const [questionsList, setQuestionsList] = useState(quizQuestions);
  const [preferencesList, setPreferencesList] = useState(preferences);
  const [playerLevelList, setPlayerLevelList] = useState(quiz.player_levels);
  const [surveyQuestionList, setSurveyQuestionList] = useState(surveyQuestions);

  // Services for Quiz association create / update / delete requests
  // DANGER!
  // This is currently the cleanest, most performant way to do a deep clone of the initial data
  // so that we can run a diff on submit and only make requests for the objects that were modified.
  // If we eventually add complex data types (see ref) to these objects, we will need to implement
  // a deep copy method, bring in a lib or simply submit everything every time and sort it out in rails.
  // Ref: https://stackoverflow.com/questions/122102/what-is-the-most-efficient-way-to-deep-clone-an-object-in-javascript/122704#122704
  const [quizSurveyQuestionAndAnswerService] = useState(new QuizSurveyQuestionAndAnswerService(JSON.parse(JSON.stringify(surveyQuestions))));
  const [quizPreferenceService] = useState(new QuizPreferenceService(JSON.parse(JSON.stringify(preferences))));
  const [quizQuestionAndOptionService] = useState(new QuizQuestionsAndOptionsService(JSON.parse(JSON.stringify(quizQuestions))));
  const [quizService] = useState(new QuizService(quiz));

  async function finalizeQuiz() {
    // hacky validations...
    const invalidInputs = findInvalidValues();
    if (invalidInputs.length > 0) {
      alert(`Please input values for: ${invalidInputs.join(', ')}`);
      return;
    }
    try {
      const quizResult = await quizService.saveData(quizData, playerLevelList);
      await quizQuestionAndOptionService.saveData(questionsList, quizResult.quiz_id);
      await quizPreferenceService.saveData(preferencesList, quizResult.quiz_id);
      await quizSurveyQuestionAndAnswerService.saveData(surveyQuestionList, quizResult.quiz_id);
      alert('Quiz saved successfully.');
      window.location.href = `/quizzes/${quizResult.quiz_id}/edit`;
    } catch(response) {
      console.log("response --> ", response);
      response.json().then((result) => alert(result.errors));

    }
  }

  function findInvalidValues() {
    var invalidFields = Object.keys(quizData).filter((k) => {
      return quizData[k] === null && !NullableQuizFields.includes(k);
    });

    if (questionsList.length === 0) {
      invalidFields.push('Add A Question');
    }

    if (quizData.ga_enabled && (quizData.ga_tracking_id === null || quizData.ga_tracking_id.length === 0)) {
      invalidFields.push('ga_tracking_id');
    }

    if (quizData.sections.includes('survey') && (surveyQuestionList.length === 0 && surveyQuestions.length === 0 )) {
      invalidFields.push('Add A Survey Question');
    }

    let missing_jot_form_id = quizData.jot_form_id === null || quizData.jot_form_id.length === 0
    let missing_jot_form_api_key = quizData.jot_form_api_key === null || quizData.jot_form_api_key.length === 0
    if (quizData.sections.includes('jotForm') && (missing_jot_form_id || missing_jot_form_api_key) ) {
      invalidFields.push('jotForm');
    }

    return invalidFields;
  }

  function navigateToQuizIndex() {
    window.open("/quizzes", "_blank");
  }

  function stageCustomGetStartedHtmlForSubmit() {
    setQuizData({...quizData, custom_get_started_html: currentCustomGetStartedHtml});
    setVisibleInputs({...visibleInputs, customGetStartedScreenInputs: false});
  }

  function resetCustomGetStartedHtml() {
    setCurrentCustomGetStartedHtml(quizData.custom_get_started_html);
    setVisibleInputs({...visibleInputs, customGetStartedScreenInputs: false});
  }

  function stageCustomFinalResultsHtmlForSubmit() {
    setQuizData({...quizData, custom_final_results_html: currentCustomFinalResultsHtml});
    setVisibleInputs({...visibleInputs, customFinalResultsScreenInputs: false});
  }

  function resetCustomFinalResultsHtml() {
    setCurrentCustomFinalResultsHtml(quizData.custom_final_results_html);
    setVisibleInputs({...visibleInputs, customFinalResultsScreenInputs: false});
  }

  // SurveyQuestion data management
  function makeSurveyQuestionEditable(surveyQuestion) {
    setCurrentSurveyQuestionData(surveyQuestion);
    setVisibleInputs({...visibleInputs, quizSurveyQuestionInputs: true});
    window.setTimeout( // Give editor component time to open before scrolling
      function () {
        document.getElementById('survey-question-input-link').scrollIntoView({ behavior: 'instant', block: 'center' });
        // window.scrollBy(0,-75); // accounts for the fixed page header
      },
    100);
  }

  function deleteSurveyQuestion(surveyQuestion) {
    // This works just like the deleteQuizQuestion function.
    if (!confirm("Are you sure you want to delete this survey question?")) { return; }

    if (surveyQuestion.id) {
      surveyQuestion.markedForDelete = true;
      var newList = surveyQuestionList.map((sq) => {
        if (sq.id === surveyQuestion.id) {
          return surveyQuestion;
        } else {
          if (sq.index > surveyQuestion.index) { sq.index = sq.index - 1; }
          return sq;
        }
      });
    } else {
      var newList = surveyQuestionList.filter(sq => sq.index !== surveyQuestion.index).map((sq) => {
        if (sq.index > surveyQuestion.index) { sq.index = sq.index - 1; }
        return sq;
      });
    }
    setSurveyQuestionList(newList);
  }

  function stageQuizSurveyQuestionForSubmit() {
    console.log(currentSurveyQuestionData)
    let invalidate = false || (currentSurveyQuestionData.text === '')
    invalidate = invalidate || currentSurveyQuestionData.answers.length == 0
    if (currentSurveyQuestionData.answers.length > 0) {
      for (let answer of currentSurveyQuestionData.answers) {
        invalidate = invalidate || (answer.text === '')
      }
    }
    if (invalidate) {
      alert('Please complete the survey question.');
      return;
    }
    if (currentSurveyQuestionData.id) {
      var newList = surveyQuestionList.map((question) => {
        return question.id === currentSurveyQuestionData.id ? currentSurveyQuestionData : question;
      });
    } else {
      const newIndex = surveyQuestionList.length;
      var newList = [...surveyQuestionList, {...currentSurveyQuestionData, index: newIndex}];
    }
    setSurveyQuestionList(newList);
    resetQuizSurveyQuestion();
  }

  function resetQuizSurveyQuestion() {
    setCurrentSurveyQuestionData(defaultSurveyQuestionData);
    setVisibleInputs({...visibleInputs, quizSurveyQuestionInputs: false});
  }
  // SurveyQuestion data management

  // Preference data management
  function makePreferenceEditable(preference) {
    setCurrentPreference(preference);
    setVisibleInputs({...visibleInputs, preferenceInputs: true});
    window.setTimeout( // Give editor component time to open before scrolling
      function () {
        document.getElementById('preference-input-link').scrollIntoView({ behavior: 'instant', block: 'center' });
      },
    100);

  }

  function deletePreference(preference) {
    // This works just like the deleteQuizQuestion function.
    if (!confirm("Are you sure you want to delete this preference?")) { return; }

    if (preference.id) {
      preference.markedForDelete = true;
      var newList = preferencesList.map((p) => {
        if (p.id === preference.id) {
          return preference;
        } else {
          if (p.index > preference.index) { p.index = p.index - 1; }
          return p;
        }
      });
    } else {
      var newList = preferencesList.filter(p => p.index !== preference.index).map((p) => {
        if (p.index > preference.index) { p.index = p.index - 1; }
        return p;
      });
    }
    setPreferencesList(newList);
  }

  function stagePreferenceForSubmit() {
    if (currentPreference.id) {
      var newList = preferencesList.map((preference) => {
        return preference.id === currentPreference.id ? currentPreference : preference;
      });
    } else {
      const newIndex = preferencesList.length;
      var newList = [...preferencesList, {...currentPreference, index: newIndex}];
    }
    setPreferencesList(newList);
    resetPreferenceInputs();
  }

  function resetPreferenceInputs() {
    setVisibleInputs({...visibleInputs, preferenceInputs: false});
    setCurrentPreference({});
  }
  // Preference data management

  // PlayerLevel data management
  function makePlayerLevelEditable(level, levelIndex) {
    // The index property is only set on the currentPlayerLevel object in the makePlayerLevelEditable
    // and exists only to distinguish new player_levels from ones that have been modified, and to mark which
    // object in the player_levels array needs to be replaced on stagePlayerLevelForSubmit.
    // It is removed in the quizService before the final submission to prevent persistence.
    setCurrentPlayerLevel({...level, index: levelIndex});
    setVisibleInputs({...visibleInputs, playerLevelInputs: true});
    window.setTimeout( // Give editor component time to open before scrolling
      function () {
        document.getElementById('player-level-input-link').scrollIntoView({ behavior: 'instant', block: 'center' });
        // window.scrollBy(0,-75); // accounts for the fixed page header
      },
    100);
  }

  function deletePlayerLevel(level, targetLevelIndex) {
    if (!confirm("Are you sure you want to delete this player level?")) { return; }

    if (level.newLevel) {
      // When deleting a level that hasn't been persisted to the database yet, all we need to do
      // is remove it from the list.
      const newList = playerLevelList.filter((l, i) => i !== targetLevelIndex);
      setPlayerLevelList(newList);
    } else {
      // Leaving existing levels in the list so they can be viewed in the preview window up to the point where the user
      // saves the quiz.  When the user clicks the main save button, those levels marked for delete will be removed from
      // from the list in the QuizService before the PUT request is made.
      level.markedForDelete = true;
      const newList = playerLevelList.map((l, index) => {
        if (index === targetLevelIndex) {
          return level;
        } else {
          return l;
        }
      });
      setPlayerLevelList(newList);
    }
  }

  function stagePlayerLevelForSubmit() {
    // if the currentPlayerLevel has an index property, it there is an old version of it in our database
    // and this one has been changed since the user opened the quiz builder.
    // The index property is deleted in the QuizService before the final submission to prevent persistence.
    if (currentPlayerLevel.index) {
      const newList = playerLevelList.map((level, i) => {
        if (i === currentPlayerLevel.index) {
          return currentPlayerLevel;
        } else {
          return level;
        }
      });
      setPlayerLevelList(newList);
    } else {
      // The newLevel property set here is just to distinguish newly added levels from ones
      // that have already been persisted in the database.
      // It is removed in the quizService before the final submit.
      setPlayerLevelList([
        ...playerLevelList,
        {...currentPlayerLevel, newLevel: true}
      ]);
    }
    resetPlayerLevelInputs();
  }

  function resetPlayerLevelInputs() {
    setVisibleInputs({...visibleInputs, playerLevelInputs: false});
    setCurrentPlayerLevel({});
  }
  // PlayerLevel data management

  // QuizQuestion data management functions
  function makeQuizQuestionEditable(question) {
    setCurrentQuizQuestionAndOptionData(question);
    setVisibleInputs({...visibleInputs, questionInputs: true});
    window.setTimeout( // Give editor component time to open before scrolling
      function () {
        document.getElementById('question-input-link').scrollIntoView({ behavior: 'instant', block: 'center' });
        // window.scrollBy(0,-75); // accounts for the fixed page header
      },
    100);
  }

  function deleteQuizQuestion(question) {
    if (!confirm("Are you sure you want to delete this question?")) { return; }

    if (question.id) {
      // Leaving existing questions in the questions list so they can still be viewed in the preview window up
      // till the point where the user saves the quiz.  When the user clicks the main save button, those questions
      // marked for delete will be sent to the appropriate route, and the others will have their index adjusted as needed.
      question.markedForDelete = true;
      var newList = questionsList.map((q) => {
        if (q.id === question.id) {
          return question;
        } else {
          if (q.index > question.index) { q.index = q.index - 1; }
          return q;
        }
      });
    } else {
      // When deleting a question that hasn't been persisted to the database yet, all we need to do
      // is remove it from the list, and adjust the index of questions that come after it.
      var newList = questionsList.filter(q => q.index !== question.index).map((q) => {
        if (q.index > question.index) { q.index = q.index - 1; }
        return q;
      });
    }
    setQuestionsList(newList);
  }

  function stageQuestionForSubmit() {
    let invalidate = false || (currentQuizQuestionAndOptionData.text === '')
    for (let answer of currentQuizQuestionAndOptionData.options) {
      invalidate = invalidate || (answer.text === '');
    }
    if (invalidate) {
      alert('Please complete the question.');
      return;
    }
    if (currentQuizQuestionAndOptionData.id) {
      var newList = questionsList.map((question) => {
        return question.id === currentQuizQuestionAndOptionData.id ? currentQuizQuestionAndOptionData : question;
      });
    } else {
      const newIndex = questionsList.length;
      currentQuizQuestionAndOptionData.id = `unsaved-${newIndex}`
      var newList = [...questionsList, {...currentQuizQuestionAndOptionData, index: newIndex}];
    }
    setQuestionsList(newList);
    resetQuizQuestionInputs();
  }

  function resetQuizQuestionInputs() {
    setVisibleInputs({...visibleInputs, questionInputs: false});
    setCurrentQuizQuestionAndOptionData({...defaultQuizQuestionAndOptionData, index: questionsList.length});
  }
  // QuizQuestion data management functions

  function stageSharedMetadataForSubmit() {
    setQuizData({...quizData, shared_metadata: currentSharedMetadata});
    setVisibleInputs({...visibleInputs, sharingMetadataInputs: false});
  }

  function resetSharedMetadata() {
    setCurrentSharedMetadata(cloneDeep(quizData.shared_metadata));
    setVisibleInputs({...visibleInputs, sharingMetadataInputs: false});
  }

  return (
    <div className="quiz-builder">
      <div className="qb-header">
      {!quizData.id ? (
        <div className="qb-title">
          New Quiz
        </div>
      ) : (
        <div className="qb-title">
          Edit Quiz: { quizData.name }
        </div>
      )}
        <button className="qb-index-link" onClick={navigateToQuizIndex}>
          Quiz Index
        </button>
      </div>

      <QuizInputs
        quizData={quizData}
        setQuizData={setQuizData}
        currentQuizQuestionAndOptionData={currentQuizQuestionAndOptionData}
        setCurrentQuizQuestionAndOptionData={setCurrentQuizQuestionAndOptionData}
      />

      { quizData.sections.includes('customGetStarted') ? (
      <fieldset className="custom-get-started-input">
      <legend>Custom Getting Started Screen</legend>
      { visibleInputs.customGetStartedScreenInputs ? (
        <div>
          <CustomGetStartedInputs
            currentCustomGetStartedHtml={currentCustomGetStartedHtml}
            setCurrentCustomGetStartedHtml={setCurrentCustomGetStartedHtml}
          />
          <button onClick={stageCustomGetStartedHtmlForSubmit}>Save Screen</button>
          <button onClick={resetCustomGetStartedHtml}>Cancel</button>
        </div>
      ) : (
        <button onClick={() => setVisibleInputs({...visibleInputs, customGetStartedScreenInputs: true})}>
          Edit Get Started Screen
        </button>
      )
      }
      </fieldset>
      )
      : null
      }

      <div id="question-input-link"></div>
      <fieldset className="question-input">
      <legend>Question Settings</legend>
      {visibleInputs.questionInputs ? (
        <div>
          <QuizQuestionInputs
            quizType={quizData.type}
            explainAnswers={quizData.explain_answers}
            showFootnote={quizData.show_question_footnotes}
            currentQuizQuestionAndOptionData={currentQuizQuestionAndOptionData}
            setCurrentQuizQuestionAndOptionData={setCurrentQuizQuestionAndOptionData}
          />
          <button onClick={stageQuestionForSubmit}>
            {currentQuizQuestionAndOptionData.id ? "Save Question" : "Add Question"}
          </button>
          <button onClick={resetQuizQuestionInputs}>Cancel</button>
        </div>
      ) : (
        <div>
          <button onClick={() => setVisibleInputs({...visibleInputs, questionInputs: true})}>
            Add New Question
          </button>
        </div>
      )}
      </fieldset>

      <div id="player-level-input-link"></div>
      <fieldset className="player-level-input">
      <legend>Player Score Tier Settings</legend>
      {visibleInputs.playerLevelInputs ? (
        <div>
          <QuizPlayerLevelInputs
            currentPlayerLevel={currentPlayerLevel}
            setCurrentPlayerLevel={setCurrentPlayerLevel}
          />
          <button onClick={stagePlayerLevelForSubmit}>Save Player Tier</button>
          <button onClick={resetPlayerLevelInputs}>Cancel</button>
        </div>
      ) : (
        <div>
          <button onClick={() => setVisibleInputs({...visibleInputs, playerLevelInputs: true})}>
            Add a Player Tier
          </button>
        </div>
      )}
      </fieldset>

      <div id="preference-input-link"></div>
      { quizData.sections.includes('quizPreferences') ?
      <fieldset className="preference-input">
      <legend>Player Contact Info &amp; Preferences</legend>
        {visibleInputs.preferenceInputs ? (
          <div>
            <QuizPreferenceInputs
              currentPreference={currentPreference}
              setCurrentPreference={setCurrentPreference}
              setPreferences={preferences}
              preferencesList={preferencesList}
            />
            <button onClick={stagePreferenceForSubmit}>
              {currentPreference.id ? "Save Preference" : "Add Preference"}
            </button>
            <button onClick={resetPreferenceInputs}>Cancel</button>
          </div>
        ) : (
          <div>
            <button onClick={() => setVisibleInputs({...visibleInputs, preferenceInputs: true})}>
              Add New Preference
            </button>
          </div>
        )}
      </fieldset>
      : null }

      <div id="survey-question-input-link"></div>
      { quizData.sections.includes('survey') ?
      <fieldset className="survey-question-input">
      <legend>Survey Questions</legend>
        { visibleInputs.quizSurveyQuestionInputs ? (
          <div>
            <QuizSurveyQuestionInputs
              currentSurveyQuestionData={currentSurveyQuestionData}
              setCurrentSurveyQuestionData={setCurrentSurveyQuestionData}
            />
            <button onClick={stageQuizSurveyQuestionForSubmit}>Save Survey Question</button>
            <button onClick={resetQuizSurveyQuestion}>Cancel</button>
          </div>
        ) : (
          <div>
            <button onClick={() => setVisibleInputs({...visibleInputs, quizSurveyQuestionInputs: true})}>
              Add New Survey Question
            </button>
          </div>
        )}
      </fieldset>
      : null }

      <div id="custom-final-results-link"></div>
      { quizData.sections.includes('customFinalResults') ?
      <fieldset className="custom-final-results-input">
        <legend>Custom Final Results Screen</legend>
        { visibleInputs.customFinalResultsScreenInputs ? (
        <div>
          <CustomFinalResultsInputs
            savedCustomFinalResultsHtml={quiz.custom_final_results_html}
            currentCustomFinalResultsHtml={currentCustomFinalResultsHtml}
            setCurrentCustomFinalResultsHtml={setCurrentCustomFinalResultsHtml}
          />
          <button onClick={stageCustomFinalResultsHtmlForSubmit}>Save Screen</button>
          <button onClick={resetCustomFinalResultsHtml}>Cancel</button>
        </div>
        ) : (
        <div>
          <button onClick={() => setVisibleInputs({...visibleInputs, customFinalResultsScreenInputs: true})}>
            Edit Final Results Screen
          </button>
        </div>
        )}
      </fieldset>
      : null }

      { visibleInputs.imageUploadInputs ? (
      <fieldset className="image-upload-container">
        <legend>Images</legend>
        <ImageUploader
          quiz={quizData}
          quizQuestions={questionsList}
        />
        <button onClick={() => setVisibleInputs({...visibleInputs, imageUploadInputs: false})}>Hide</button>
      </fieldset>
      ) : quiz.id ? (
      <fieldset className="image-upload-container">
        <legend>Images</legend>
        <button onClick={() => setVisibleInputs({...visibleInputs, imageUploadInputs: true})}>
          Manage Images
        </button>
      </fieldset>
      ) : (
      <fieldset className="image-upload-container">
        <legend>Images</legend>
        <div className="input-detail">Images can only be added to saved quizzes.</div>
      </fieldset>
      )}

      { quizData.social_sharing_enabled ?
        <fieldset className="sharing-metadata-container">
          <legend>Social Sharing Metadata</legend>
          { visibleInputs.sharingMetadataInputs ? (
            <div>
              <SharedMetadataInputs
                currentSharedMetadata={currentSharedMetadata}
                setCurrentSharedMetadata={setCurrentSharedMetadata}
              />
              <button onClick={stageSharedMetadataForSubmit}>Save Sharing Data</button>
              <button onClick={resetSharedMetadata}>Cancel</button>
            </div>
          ) : (
            <div>
              <button onClick={() => setVisibleInputs({...visibleInputs, sharingMetadataInputs: true})}>
                Edit Sharing Data
              </button>
            </div>
          )}
        </fieldset>
      : null }

      { visibleInputs.cssUploadInputs ? (
        <fieldset className="custom-css-upload-container">
          <legend>Custom Styles</legend>
          <CssUploader quiz={quizData} />
          <button onClick={() => setVisibleInputs({...visibleInputs, cssUploadInputs: false})}>Hide</button>
        </fieldset>
      )
      : quiz.id ? (
        <fieldset className="custom-css-upload-container">
          <legend>Custom Styles</legend>
          <button onClick={() => setVisibleInputs({...visibleInputs, cssUploadInputs: true})}>
            Edit Custom Styles
          </button>
        </fieldset>
      ) :
        <fieldset className="custom-css-upload-container">
          <legend>Custom Styles</legend>
          <div className="input-detail">Custom CSS can only be added to saved quizzes.</div>
        </fieldset>
      }

      <QuizPreview
        quizData={quizData}

        playerLevelList={playerLevelList}
        makePlayerLevelEditable={makePlayerLevelEditable}
        deletePlayerLevel={deletePlayerLevel}

        preferencesList={preferencesList}
        makePreferenceEditable={makePreferenceEditable}
        deletePreference={deletePreference}

        surveyQuestionsList={surveyQuestionList}
        makeSurveyQuestionEditable={makeSurveyQuestionEditable}
        deleteSurveyQuestion={deleteSurveyQuestion}

        questionsList={questionsList}
        makeQuizQuestionEditable={makeQuizQuestionEditable}
        deleteQuizQuestion={deleteQuizQuestion}
      />

      <div className="submit-container">
        <button className="save-quiz" onClick={finalizeQuiz}>Save Quiz</button>
      </div>
      <AdminMenu
        page='QuizBuilder'
      />
    </div>
  );
}

export default QuizBuilder
