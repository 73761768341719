import React, { useState } from 'react';
import lwAjax from 'utilities/lwAjax';

function CssUploader(props) {
  const {
    quiz
  } = props;

  const [currentCssFiles, setCurrentCssFiles] = useState(quiz.custom_css_files);


  function uploadCustomCss(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    lwAjax('POST', `/quizzes/${quiz.id}/quiz_attachments/attach_custom_css_file`, handleCssUpload, formData).catch((error) => {
      alert("css upload error");
      console.error('Error:', error);
    });
  }

  function handleCssUpload(data) {
    if (data.errors) {
      alert("image upload error");
      console.error('Error:', data['errors']);
    } else {
      console.log('Success:', data);
      setCurrentCssFiles([...currentCssFiles, data.new_custom_css_file]);
      alert("successfully uploaded CSS file");
    }
  }

  function deleteCssFile(cssFile) {
    const deleteUrl = `/quizzes/${quiz.id}/quiz_attachments/delete_custom_css_file`;
    lwAjax('DELETE', deleteUrl, handleCssFileDelete, JSON.stringify({custom_css_file_id: cssFile.id})).catch((error) => {
      alert("css file delete error");
      console.error('Error:', error);
    });
  }

  function handleCssFileDelete(response) {
    const filteredFiles = currentCssFiles.filter(file => file.id !== response.result.id);
    setCurrentCssFiles(filteredFiles);
    alert(`${response.result.nickname || 'file'} deleted`);
  }

  function toggleActive(cssFile, event) {
    const updateUrl = `/quizzes/${quiz.id}/quiz_attachments/update_custom_css_file/${cssFile.id}`;
    lwAjax('PUT', updateUrl, handleCssFileUpdate, JSON.stringify({active: event.target.checked})).catch((error) => {
      alert("css file update error");
      console.error('Error:', error);
    });
  }

  function handleCssFileUpdate(response) {
    if (!response.errors) {
      const updatedFiles = currentCssFiles.map(file => {
        return file.id === response.updated_custom_css_file.id ? response.updated_custom_css_file : file;
      });
      setCurrentCssFiles(updatedFiles);
      alert(`File set to ${response.updated_custom_css_file.active ? 'active' : 'inactive'}`);
    } else {
      alert("error updating file")
    }
  }

  return (
    <div className="custom-css-uploader">
      <form className="custom-css-file-form" name="custom_css_file" onSubmit={uploadCustomCss}>
        <label className="custom-css-label">Upload custom CSS styles for quiz</label>
        <div className="custom-css-file-inputs-wrapper">
          <input name="custom_css_file" type="file" />
          <input name="nickname" type="text" placeholder="CSS file nickname"/>
          <label>Active</label>
          <input name="active" type="checkbox"/>
        </div>
        <button type="submit" >Save File</button>
      </form>
      { currentCssFiles.length > 0 ? (
      <div className="upload-quiz-custom-css-file">
        <fieldset>
          <legend>Current CSS Files:</legend>

          <ul className="custom-css-file-list">
          {currentCssFiles.sort((a, b) => a.id - b.id).map((cssFile) =>
            <li key={cssFile.id}>
              <a title="download file" target="_blank" href={cssFile.url}>{cssFile.nickname || cssFile.filename}</a>
              <label>Toggle Active</label>
              <input
                name="active"
                type="checkbox"
                checked={cssFile.active || false}
                onChange={(e) => toggleActive(cssFile, e)}
              />
              <i
                title="delete css file"
                className="fas fa-trash-alt"
                onClick={() => deleteCssFile(cssFile)}
              ></i>
            </li>
          )}
          </ul>
        </fieldset>
      </div>)
      : null }
    </div>
  );
}

export default CssUploader
