import React, { useState, useEffect } from 'react';
import lwAjax from 'utilities/lwAjax';

function AdminSignIn(props) {

  const {
    setAdmin
  } = props;

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  const contentType = props.content_type || 'json';

  useEffect(() => {
    // flash: [["alert", "Invalid Email or password."]]
    if (props.flash && props.flash[0] && props.flash[0][0] && props.flash[0][0] === 'alert') {
      setErrorMessage(props.flash[0][1]);
    }
  }, []);

  function handleEmailChange(e) {
    setEmail(e.target.value);
  }

  function handlePasswordChange(e) {
    setPassword(e.target.value);
  }

  function handleSubmit(e) {
    // If this is the embedded form, make our JSON request to sign in
    // Otherwise submit the form as usual
    if (contentType === 'json') {
      e.preventDefault();
      // post up admin creds
      adminSignIn();
    }
  }

  function adminSignIn() {
    // clear any previous sign in error
    setErrorMessage('');
    const body = JSON.stringify({ admin: { email: email, password: password } });
    lwAjax('POST', "/admins/sign_in.json", handleAdminSignInSuccess, body).catch((error) => {
      console.error('adminSignIn error:', error);
    });
  }

  function handleAdminSignInSuccess(data) {
    console.log('Successful login response:', data);
    if (data.error) {
      setErrorMessage(data.error);
    } else {
      props.setAdmin(data);
      setEmail('');
      setPassword('');
    }
  }

  return (
    <form action={props.sessionPath} method="post" className="sign-in-form" onSubmit={handleSubmit}>
      <ul className="sign-in-form-fields">
        <li className="sign-in-header">
          Admin Sign In
        </li>
        <li>
          <input type="text" name="admin[email]" onChange={handleEmailChange} placeholder="Email" />
        </li>
        <li>
          <input type="password" name="admin[password]" onChange={handlePasswordChange} placeholder="Password" />
        </li>
        <li>
          <input type="submit" className="sign-in-button" value="Sign in" />
        </li>
        {errorMessage != "" ?
          <li className="error-message">{errorMessage}</li> :
          null
        }
      </ul>
    </form>
  )
}

export default AdminSignIn;
