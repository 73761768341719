import React, { useState } from 'react';

function QuizPreferenceInputs(props) {

  const {
    currentPreference,
    setCurrentPreference,
    preferencesList
  } = props;

  function handlePreferenceTextChange(e) {
    setCurrentPreference({
      ...currentPreference,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  function handlePreferenceLeaderboardChange(e) {
    setCurrentPreference({
      ...currentPreference,
      [e.currentTarget.name]: e.currentTarget.checked
    });
  }

  return (
    <div className="preference-builder">
      <div className="input-group">
        <label>Preference Text:
          <input
            type="text"
            name="text"
            onChange={handlePreferenceTextChange}
            value={currentPreference.text || ''}
          />
        </label>
      </div>
      { preferencesList.filter(e => e.show_leaderboard !== null).length === 0 ? (
      <div className="input-group">
        <label>Leaderboard Opt-in:
          {/* TODO - we should disable this checkbox if a show_leaderboard pref has already been added - we don't want multiples */}
          <input
            type="checkbox"
            name="show_leaderboard"
            onChange={handlePreferenceLeaderboardChange}
            checked={currentPreference.show_leaderboard || false}
          />
        </label>
      </div>
      ) : null}
    </div>
  );
}

export default QuizPreferenceInputs
