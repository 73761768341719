// Built on the same principals as useInterval.
import React, { useRef, useEffect } from 'react';
import consumer from "../channels/consumer"

export default function useActionCable(channelName, recCallback) {
  const savedCallback = useRef();

  // Called after every render
  useEffect(() => {
    savedCallback.current = recCallback;
  });

  // Called after first render only
  useEffect(() => {
    function dataReceived(data) {
      savedCallback.current(data);
    }

    const channelSub = consumer.subscriptions.create(channelName, {
      connected() {
        const channelNameStr = typeof channelName == 'string' ? channelName : channelName.channel;
        console.log(`Connected to ${channelNameStr}`);
      },

      disconnected() {
        const channelNameStr = typeof channelName == 'string' ? channelName : channelName.channel;
        console.log(`${channelNameStr} disconnected`);
      },

      received(data) {
        dataReceived(data);
      }
    });

    return () => {
      channelSub.unsubscribe();
      console.log(`Disconnected from ${channelName}`);
    }
  }, []);
}
