import React, { useEffect } from 'react';
import lwAjax from 'utilities/lwAjax';

export default function useLwAjax(config) {
  const {
    method,
    url,
    success,
    body,
    triggers=[],
    onlyRunIf=()=>{return true;}
  } = config;

  useEffect(() => {
    if (!onlyRunIf()) {
      return;
    }

    const makeRequest = () => {
      lwAjax(method, url, success, body).catch((error) => {
        console.log("Error retrieving data", error);
      });
    };
    makeRequest();
  }, triggers);
}