import React, { useState } from 'react';
import lwAjax from 'utilities/lwAjax';

function ImageUploader(props) {
  const {
    quiz,
    quizQuestions
  } = props;

  const [currentMiscImages, setCurrentMiscImages] = useState(quiz.misc_images);
  const [mainBackgroundImage, setMainBackgroundImage] = useState(quiz.main_background_image);
  const [leaderboardBackgroundImage, setLeaderboardBackgroundImage] = useState(quiz.leaderboard_background_image);

  function uploadQuizImage(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const uploadUrl = `/quizzes/${quiz.id}/quiz_attachments/attach_${e.currentTarget.name}`;
    lwAjax('POST', uploadUrl, handleImageUpload, formData).catch((error) => {
      alert("image upload error")
      console.error('Error:', error);
    });
  }

  function uploadQuestionImage(e) {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);
    const uploadUrl = `/quizzes/${quiz.id}/quiz_question_attachments/${e.currentTarget.id}/attach_image`;
    lwAjax('POST', uploadUrl, handleImageUpload, formData).catch((error) => {
      alert("image upload error");
      console.error('Error:', error);
    });
  }

  function handleImageUpload(data) {
    if (data.errors) {
      alert("image upload error")
      console.error('Error:', data.errors);
    } else {
      console.log('Success:', data);
      if (data.new_misc_image) {
        setCurrentMiscImages([...currentMiscImages, data.new_misc_image])
      }
      if (data.main_background_image) {
        setMainBackgroundImage(data.main_background_image)
      }
      if (data.leaderboard_background_image) {
        setLeaderboardBackgroundImage(data.leaderboard_background_image)
      }
      alert("successfully uploaded image");
    }
  }

  async function copyUrlTextToClipboard(url) {
    try {
      await navigator.clipboard.writeText(url);
    } catch (err) {
      console.log("err --> ", err);
    }
  }

  function deleteImage(image) {
    const deleteUrl = `/quizzes/${quiz.id}/quiz_attachments/delete_misc_image`;
    lwAjax('DELETE', deleteUrl, handleImageDelete, JSON.stringify({misc_image_id: image.id})).catch((error) => {
      alert("image delete error");
      console.error('Error:', error);
    });
  }

  function handleImageDelete(response) {
    const filteredImages = currentMiscImages.filter(image => image.id !== response.result.id);
    setCurrentMiscImages(filteredImages);
    alert(`${response.result.nickname} deleted`);
  }

  return (

    <div className="image-uploader">
      <div className="upload-quiz-main-image">
        <fieldset>
          <legend>Quiz Background Image:</legend>
          <form name="main_background_image" onSubmit={uploadQuizImage}>
            <input name="main_background_image" type="file" />
            <button type="submit" >Save Image</button>
            { mainBackgroundImage.public_url ? (
              <a className="image_link" href={mainBackgroundImage.public_url} target="_blank">{mainBackgroundImage.filename}</a>
            ) : <span className="image_link"></span> }
          </form>
        </fieldset>
      </div>

      <div className="upload-quiz-leaderboard-image">
        <fieldset>
          <legend>Leaderboard Background Image:</legend>
          <form name="leaderboard_background_image" onSubmit={uploadQuizImage}>
            <input name="leaderboard_background_image" type="file" />
            <button type="submit">Save Image</button>
            { leaderboardBackgroundImage.public_url ? (
              <a className="image_link" href={leaderboardBackgroundImage.public_url} target="_blank">{leaderboardBackgroundImage.filename}</a>
            ) : <span className="image_link"></span> }
          </form>
        </fieldset>
      </div>

      <div className="upload-quiz-question-image">
        <fieldset>
          <legend>Question Images:</legend>
        {quizQuestions.length > 0 ? (
          <div className="ul-quiz-questions">
          {quizQuestions.every(qq => qq.id) ? null :
            <div className="qb-warning">
              You have unsaved quiz questions.  You must save this quiz before images can be added to them.
            </div>
          }
          {quizQuestions.filter(qq => qq.id).map((question) =>
            <form
              key={question.id}
              id={question.id}
              className="quiz-question"
              onSubmit={uploadQuestionImage}
              name="quiz_question_image">
              <div className="question-label">
                <span>Q{question.index + 1}</span> <span className="question-id">ID: {question.id}</span>
              </div>
              <input name="quiz_question_image" type="file" />
              <button type="submit" >Save Image</button>
              <div className="question-text">{question.text}</div>
            </form>
          )}
          </div>
        ) : (
          <div className="no-questions-message">This quiz has no questions</div>
        )}
        </fieldset>
      </div>

      <div className="upload-quiz-misc-images">
        <fieldset>
          <legend>Miscellaneous Images:</legend>
          <form className="misc-images-form" name="misc_image" onSubmit={uploadQuizImage}>
            <div className="misc-image-inputs-wrapper">
              <input name="misc_image" type="file" />
              <input name="misc_image_nickname" type="text" placeholder="Image nickname"/>
            </div>
            <button type="submit">Save Image</button>
          </form>

          {currentMiscImages.length > 0 ? (
          <fieldset className="current-images-list">
            <legend className="current-images-list-label">Current Images:</legend>
            <ul className="misc-image-urls-list">
            {currentMiscImages.map((image) =>
              <li key={image.id}>
                <div className="nickname">{image.nickname || image.filename}</div>
                <a
                  className="truncated-url"
                  target="_blank"
                  rel="noopener noreferrer"
                  href={image.url}
                >{image.url}</a>
                <i
                  title="copy url to clipboard"
                  className="far fa-copy"
                  onClick={() => copyUrlTextToClipboard(image.url)}
                ></i>
                <i
                  title="delete image"
                  className="fas fa-trash-alt"
                  onClick={() => deleteImage(image)}
                ></i>
              </li>
            )}
            </ul>
          </fieldset>
          ) : null }
        </fieldset>
      </div>

    </div>
  );
}

export default ImageUploader
