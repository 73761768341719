import React, { useState } from 'react';
import useHelpfulErrors from 'hooks/useHelpfulErrors';
import useFetch from 'hooks/useFetch';
import lwAjax from 'utilities/lwAjax';

function QuizPreferences(props) {
  // We'll need to retrieve the Player Result "label" AcroXXXXXX when this loads
  // None of the fields in this form are required
  const {
    activeQuiz,
    activePlayerId,
    transitionToNextSection
  } = props;


  const [playerFirstName, setPlayerFirstName] = useState('');
  const [playerLastName, setPlayerLastName] = useState('');
  const [playerEmail, setPlayerEmail] = useState('');
  const [playerUpdateError, setPlayerUpdateError] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [preferences, setPreferences] = useState([]);
  const [preferenceResponses, setPreferenceResponses] = useState({});
  const [playerLevel, setPlayerLevel] = useState('');


  useFetch('GET', `/${activeQuiz.obfuscated_id}/players/${activePlayerId}/level.json`, handlePlayerLevelData);
  useFetch('GET', `/quizzes/${activeQuiz.id}/quiz_preferences`, handleQuizPrefData);

  function handleQuizPrefData(data) {
    setPreferences(data);

    // Set the default response values for the Preferences checkboxes
    // We'll submit a response even if the Player doesn't check anything
    const defaultPreferenceResponses = {};
    data.map((preference, index) => {
      const value = preference.show_leaderboard ? true : false;
      defaultPreferenceResponses[preference.id] = value;
    });
    setPreferenceResponses(defaultPreferenceResponses);
  }

  function handlePlayerLevelData(data) {
    if (!data.error) {
      setPlayerLevel(data.level_name);
    }
  }

  // Player data
  function handlePlayerFirstNameChange(e) {
    setPlayerFirstName(e.target.value);
  }

  function handlePlayerLastNameChange(e) {
    setPlayerLastName(e.target.value);
  }

  function handlePlayerEmailChange(e) {
    setPlayerEmail(e.target.value);
  }

  function handleSubmit(e) {
    e.preventDefault();
    updatePlayer();
  }

  function updatePlayer() {
    setPlayerUpdateError(false);
    const body = JSON.stringify({ player: { first_name: playerFirstName, last_name: playerLastName, email: playerEmail }});
    lwAjax('PUT', "/players.json", handlePlayerUpdate, body).catch((error) => {
      setPlayerUpdateError(true);
      console.error('Error in updatePlayer: ', error);
    })
  }

  function handlePlayerUpdate(data) {
    console.log('updatePlayer success: data: ', data);
    const errors = data.errors;
    if (errors) {
      // Player update errors
      setPlayerUpdateError(true);
      setErrorMessage(useHelpfulErrors(errors));
    } else {
      // Now send the preferences
      updatePlayerPreferences();
    }
  }

  function updatePlayerPreferences() {
    const body = JSON.stringify({ preference_responses: preferenceResponses });
    lwAjax('POST', `/${activeQuiz.obfuscated_id}/preference_responses.json`, handlePlayerPrefsCreate, body).catch((error) => {
      setPlayerUpdateError(true);
      console.error('Error in updatePlayerPreferences: ', error);
    });
  }

  function handlePlayerPrefsCreate(data) {
    console.log('updatePlayerPreferencesSuccess: data: ', data);
    const errors = data.errors;
    if (errors) {
      // Player update errors
      setPlayerUpdateError(true);
      setErrorMessage(useHelpfulErrors(errors));
    }
    transitionToNextSection();
  }

  // Preferences
  function handlePreferenceChange(e) {
    setPreferenceResponses({
      ...preferenceResponses,
      [e.currentTarget.name]: e.currentTarget.checked
    })
  }

  return (
    <div className="flex quiz-preferences">
      <div className="flex quiz-preferences-content">
        <div className="quiz-preferences-header">
          {playerLevel != "" ? playerLevel : null }
        </div>
        <form className="quiz-preferences-form" onSubmit={handleSubmit}>
          {playerUpdateError ? <div className="error-message">{`${errorMessage}`}</div>: null}
          <div className="player-data-inputs">
            <input type="text" className="player-data-input" name="playerFirstName" autoComplete="off" placeholder="Enter First Name" onChange={handlePlayerFirstNameChange} />
            <input type="text" className="player-data-input" name="playerLastName" autoComplete="off" placeholder="Enter Last Name" onChange={handlePlayerLastNameChange} />
            <input type="text" className="player-data-input" name="playerEmail" autoComplete="off" placeholder="Enter Email" onChange={handlePlayerEmailChange} />
          </div>
          {preferences.length > 0 ?
            <div className="player-preferences">
              {/* retrieved preference questions */}
              { preferences.map((preference, index) =>
                <div className="preference" key={index}>
                  <input type="checkbox" className="preference-check" defaultChecked={preference.show_leaderboard} id={`preference-${preference.id}`} name={preference.id} onChange={handlePreferenceChange} />
                  <label className="preference-label" htmlFor={`preference-${preference.id}`}>{preference.text}</label>
                  <div className="check"></div>
                </div>
              )}
            </div>
          : null}
          <div className="player-score-submit">
          {
            <button className="player-score-submit-button" type="submit">Click to see my score!</button>
          }
          </div>
        </form>
      </div>
    </div>
  );
}

export default QuizPreferences
