import React, { useState } from 'react';

function QuizQuestionInputs(props) {
  const {
    quizType,
    explainAnswers,
    showFootnote,
    currentQuizQuestionAndOptionData,
    setCurrentQuizQuestionAndOptionData
  } = props;

  const [showIndexWarning, setShowIndexWarning] = useState(false);

  function handleQuestionInputChange(e) {
    setCurrentQuizQuestionAndOptionData({
      ...currentQuizQuestionAndOptionData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  function handleOptionInputChange(e, targetOption) {
    const property = e.target.name;
    const value = property === 'is_correct' ? e.target.checked : e.target.value;
    const updatedOptions = currentQuizQuestionAndOptionData.options.map((option) => {

      if (quizType === "StandardQuiz") {
        if (option.index === targetOption.index) {
          // For the option being modified, just swap out the old property's value for the new one.
          return property === 'is_correct' && option.is_correct ? option : {...option, [property]: value};
        } else {
          // For the option that was correct previously, we need to set that value to false if a new
          // correct answer has been selected.
          return property === 'is_correct' && option.is_correct ? {...option, is_correct: false} : option;
        }
      } else {
        // For a WeightedQuiz, no need to mess with is_correct, that is handled on the Rails side, so set the new property value
        if (option.index === targetOption.index) {
          return {...option, [property]: value};
        } else {
          return option
        }
      }
    });

    // For a WeightedQuiz, is_correct should be true for all question options
    // We'll handle this on the Rails side when the options are submitted
    setCurrentQuizQuestionAndOptionData({
      ...currentQuizQuestionAndOptionData,
      options: updatedOptions
    });
  }

  function addOption() {
    currentQuizQuestionAndOptionData.options.push({text: "", index: currentQuizQuestionAndOptionData.options.length, is_correct: false})
    setCurrentQuizQuestionAndOptionData({
      ...currentQuizQuestionAndOptionData,
      options: currentQuizQuestionAndOptionData.options
    });
  }

  function deleteOption(option, i) {
    let updatedOptions = currentQuizQuestionAndOptionData.options.filter((o) => { return o.index != option.index });
    if (option.is_correct) { updatedOptions[0].is_correct = true }
    updatedOptions.forEach((o, i) => o.index = i);

    setCurrentQuizQuestionAndOptionData({
      ...currentQuizQuestionAndOptionData,
      options: updatedOptions
    });
  }

  return (
    <div className="question-builder">
      <div className="input-group">
        <label htmlFor="quiz-question-text-input">Question Text:</label>
        <textarea
          id="quiz-question-text-input"
          name="text"
          onChange={handleQuestionInputChange}
          value={currentQuizQuestionAndOptionData.text}
        ></textarea>
      </div>


    {
      // Answer Value header
      quizType === 'WeightedQuiz' ?
        <div className="input-group">
          <div className="input-group-header-left"></div>
          <div className="input-group-header-right">Answer Value</div>
        </div> : null
    }

    { currentQuizQuestionAndOptionData.options.map((option, i) =>
      <div key={`${option.index}-${option.id}`} className="button-answer">
        <form className="input-group" onChange={(e) => handleOptionInputChange(e, option)}>
          <label htmlFor={`quiz-question-${i}-answer-input`}>Answer #{i + 1}:</label>
          <input
            id={`quiz-question-${i}-answer-input`}
            name="text"
            type="text"
            value={option.text}
            onChange={()=>{}}
          />
          {
            // Display trash can icon
            currentQuizQuestionAndOptionData.options.length > 2 ?
            <i
              title="delete answer"
              className="fas fa-trash-alt"
              onClick={() => deleteOption(option)}
            ></i> : null
          }

          {
            // Standard Quiz correct answer radio button
            quizType === 'StandardQuiz' ?
            <div className={`is-correct${option.is_correct ? ' correct-answer' : ''}`}>
              <label htmlFor={`quiz-question-${i}-answer-correct-checkbox`}>Correct?</label>
              <input
                id={`quiz-question-${i}-answer-correct-checkbox`}
                name="is_correct"
                type="radio"
                checked={option.is_correct}
                onChange={()=>{}}
              />
            </div> : null
          }

          {
            // Weighted Quiz answer value input
            quizType === 'WeightedQuiz' ?
            <div>
              <input
                type="number"
                id={`quiz-question-${i}-weighted-answer-value`}
                className="input-weighted-value"
                name="weighted_value"
                defaultValue="1"
                value={option.weighted_value}
                onChange={()=>{}}
                max="9999"
              />
              <input type="hidden" name="is_correct" value="true" />
            </div> : null
          }
        </form>
      </div>
    )}

    { currentQuizQuestionAndOptionData.options.length < 12 ?
      <div className="add-answer input-group">
        <label htmlFor="add-answer-btn"> </label>
        <button id="add-answer-btn" onClick={addOption}>Add Answer</button>
        <div className="note"><b>Note:</b> Too many answer options confuses users and makes your quiz layout harder.</div>
      </div> : null
    }

    { showFootnote ?
      <div className="input-group">
        <label htmlFor="quiz-question-footnote-input">Footnote:</label>
        <textarea
          id="quiz-question-footnote-input"
          name="footnote"
          type="text"
          onChange={handleQuestionInputChange}
          value={currentQuizQuestionAndOptionData.footnote}
        >
        </textarea>
      </div> :
      currentQuizQuestionAndOptionData.footnote ?
        <div className="qb-warning">Enable "Question Footnotes" above to edit them for this question</div> : null
    }

    { explainAnswers ?
      <div className="answer-explanations-container">
        <div className="input-group">
          <label htmlFor="correct-answer-explanation-input">Correct Explanation:</label>
          <textarea
            id="correct-answer-explanation-input"
            name="correct_answer_explanation"
            type="text"
            onChange={handleQuestionInputChange}
            value={currentQuizQuestionAndOptionData.correct_answer_explanation}
          ></textarea>
        </div>
        <div className="input-group">
          <label htmlFor="incorrect-answer-explanation-input">Incorrect Explanation:</label>
          <textarea
            id="incorrect-answer-explanation-input"
            name="incorrect_answer_explanation"
            type="text"
            onChange={handleQuestionInputChange}
            value={currentQuizQuestionAndOptionData.incorrect_answer_explanation}
          ></textarea>
        </div>
      </div> :
      currentQuizQuestionAndOptionData.correct_answer_explanation || currentQuizQuestionAndOptionData.incorrect_answer_explanation ?
        <div className="qb-warning">Enable "Immediate Feedback" and "Explain Answers" above to edit them for this question</div> : null
    }
    </div>
  );
}

export default QuizQuestionInputs
