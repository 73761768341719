import React, { useState } from 'react';
import useInterval from 'hooks/useInterval';
import useFetch from 'hooks/useFetch';
import formatTime from 'utilities/formatTime';
import lwAjax from 'utilities/lwAjax';

function QuizQuestions(props) {
  const {
    quiz,
    initInactivityTimeout,
    transitionToInactivityCountdown,
    transitionToNextSection,
    playerCreated,
    activePlayerId,
  } = props;

  // Immediate feedback vars
  const [correctAnswerSelected, setCorrectAnswerSelected] = useState(false);
  const [incorrectAnswerSelected, setIncorrectAnswerSelected] = useState(false);
  const [selectedOptionIndex, setSelectedOptionIndex] = useState(null);
  const [explanationTextAdjustmentClass, setExplanationTextAdjustmentClass] = useState('');
  // Interval toggle
  const [pauseIntervalEffect, setPauseIntervalEffect] = useState(false);

  const [questionList, setQuestionList] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({text: '', options: []});
  const [selectedOptions, setSelectedOptions] = useState([]);

  const [displayedTime, setDisplayedTime] = useState("0:00");
  const [timerPulseClass, setTimerPulseClass] = useState('');

  const [quizSecondsElapsed, setQuizSecondsElapsed] = useState(0);
  const [currentQuestionSecondsElapsed, setCurrentQuestionSecondsElapsed] = useState(0);
  const [optionsAdjustmentClass, setOptionsAdjustmentClass] = useState(quiz.options.length === 0 ? ' no-options' : '');

  useFetch('GET', `/quizzes/${quiz.id}/quiz_questions`, handleInitData);

  function handleInitData(data) {
    setQuestionList(data);
    setCurrentQuestion(data[0]);
  }

  useInterval(handleSecondElapsed, 1000);

  function handleSecondElapsed() {
    // This is a hack.  The interval itself should be stopped to conserve resources, but since
    // resources are bountiful at the moment, and because of how hooks work, that is going to have
    // to wait until a later iteration of this feature.
    if (!pauseIntervalEffect) {
      const newQuizSecondsElapsed = quizSecondsElapsed + 1;
      setQuizSecondsElapsed(newQuizSecondsElapsed);
      setCurrentQuestionSecondsElapsed(currentQuestionSecondsElapsed + 1);
      const isPulseTime = newQuizSecondsElapsed > 0 && newQuizSecondsElapsed % 10 === 0;
      const newDisplayTime = formatTime(newQuizSecondsElapsed);

      isPulseTime ? setTimerPulseClass('pulse') : setTimerPulseClass('');
      setDisplayedTime(newDisplayTime);
    }
  }

  function handleOptionSelected(option) {
    if (needsPageReload) {
      setShowReloadPage(true);
    }
    if (isQuestionAnswered(currentQuestion) || !playerCreated) {
      console.log("playerCreated --> ", playerCreated);
      // Prevent the user from submitting multiple responses to the same question.
      return;
    }
    const selectedOptionId = option.id;
    const newOption = {
      quiz_question_id: +currentQuestion.id,
      quiz_option_id: +selectedOptionId,
      seconds_to_answer: currentQuestionSecondsElapsed,
      current_player_id: +sessionStorage.getItem('activePlayerId'),
      session_id: sessionStorage.getItem('sessionId')
    }
    gtag('event', 'click', {event_category: `Selected Option Index: ${option.index}`, event_label: `${currentQuestion.id}.${selectedOptionId}`});
    setSelectedOptions([...selectedOptions, newOption]);
    setCurrentQuestionSecondsElapsed(0);
    const quizResponseBody = JSON.stringify(newOption);
    lwAjax('POST', `/${quiz.obfuscated_id}/quiz_responses`, handleQuizResponseCreate, quizResponseBody).then(() => {
      if (quiz.immediate_feedback) {
        pauseQuestionTimer();
        handleFeedbackActivation(option);
      } else {
        transitionToNext();
      }
    }, (response) => {
      response.json().then((result) => {
        if (result.errors && result.errors.error && result.errors.error.length > 0) {
          if (result.errors.error[0] =~ /^Expected value from cache/) {
            setNeedsPageReload(true);
            setShowReloadPage(true);
          }
        }
      });

    });
  }

  function isQuestionAnswered(question) {
    return selectedOptions.findIndex(o => o.quiz_question_id === question.id) !== -1;
  }

  function pauseQuestionTimer() {
    setPauseIntervalEffect(true);
  }

  function startQuestionTimer() {
    setPauseIntervalEffect(false);
  }

  function handleFeedbackActivation(selectedOption) {
    if (quiz.immediate_feedback) {
      setAnswerVars(selectedOption);
    }
  }

  function setAnswerVars(selectedOption) {
    setCorrectAnswerSelected(selectedOption.is_correct);
    setIncorrectAnswerSelected(!selectedOption.is_correct);
    setSelectedOptionIndex(selectedOption.index);

    if (quiz.explain_answers && explanationTextPresent(selectedOption)){
      setExplanationTextAdjustmentClass(' explain-answers');
    }
  }

  function explanationTextPresent(selectedOption) {
    return (selectedOption.is_correct && currentQuestion.correct_answer_explanation.length > 0) ||
           (!selectedOption.is_correct && currentQuestion.incorrect_answer_explanation.length > 0);
  }

  function resetAnswerVars() {
    setCorrectAnswerSelected(false);
    setIncorrectAnswerSelected(false);
    setSelectedOptionIndex(null)
    setExplanationTextAdjustmentClass('');
  }

  function handleQuizResponseCreate(data) {
  }

  function handleQuizScoreCreate(data) {
    // Quiz responses submitted successfully, move on to next section
    const score = data && data.result && data.result.score;

    const correct_response_count = score && score.correct_response_count;
    sessionStorage.setItem('answersCorrect', correct_response_count);

    const total_response_count = score && score.total_response_count
    sessionStorage.setItem('questionCount', total_response_count);

    const level_name = data && data.result && data.result.level_data && data.result.level_data.level_name
    sessionStorage.setItem('playerTier', level_name);
    transitionToNextSection();
  }

  function optionIcon(option) {
    if (option.is_correct) {
      return "fas fa-check";
    } else {
      return "far fa-times-circle";
    }
  }

  function handleNextButtonClick(e) {
    gtag('event', 'click', {event_category: 'next-button', event_label: `${currentQuestion.id}`});
    resetAnswerVars();
    transitionToNext();
    startQuestionTimer();
  }

  function transitionToNext() {
    const nextQuestion = questionList.find(q => q.index === currentQuestion.index + 1);
    if (nextQuestion) {
      initInactivityTimeout();
      setCurrentQuestion(nextQuestion);
    } else {
      // This is a weird route right now due to the way this was initially built, then split out.
      // Currently there is no need for a body in the request because the quiz_score objects
      // are built with the quiz_response objects that the current_user created on each option select.
      const params = {
        current_player_id: +sessionStorage.getItem('activePlayerId'),
        session_id: sessionStorage.getItem('sessionId')
      }
      const body = JSON.stringify(params);
      lwAjax('POST', `/${quiz.obfuscated_id}/quiz_scores`, handleQuizScoreCreate, body);
    }
  }

  const [showReloadPage, setShowReloadPage] = useState(false);
  const [needsPageReload, setNeedsPageReload] = useState(false);

  function confirmReloadPage() {
    setShowReloadPage(false);
    setNeedsPageReload(false);
    window.location.reload(false);
  }

  function closeReloadPage() {
    setShowReloadPage(false);
    setNeedsPageReload(true);
  }

  return (
    <div className="flex quiz-questions">
      <div
        className={'flex quiz-questions-content question-'
          + (currentQuestion.index + 1)
          + ' of-' + (questionList.length)
          + (selectedOptionIndex !== null ? ' answer-' + selectedOptionIndex : '')
          + (correctAnswerSelected || incorrectAnswerSelected ? (correctAnswerSelected ? ' correct' : ' incorrect') : '')
          + `${explanationTextAdjustmentClass}`}
        data-questions-count={(questionList.length)}>
        <div className={`question-header${optionsAdjustmentClass}`}>
          <div className="question-header-index">
            {'Question '+(currentQuestion.index + 1 || 0 )+'/'+ questionList.length }
          </div>
          { quiz.options.includes('homeButton') ?
            <div className="question-header-home" title="Home" onClick={transitionToInactivityCountdown}></div> : null
          }
          { quiz.options.includes('questionTimer') ?
            <div className="question-header-timer">
              Time:<span className={timerPulseClass}>{ displayedTime }</span>
            </div> : null
          }
        </div>

        <div className="question-body">
          <div className="question-image">
            <img src={currentQuestion.image_url} alt={currentQuestion.image_alt} />
          </div>
          <div className="question-text">
            { currentQuestion.text }
          </div>
        </div>

        <div className="question-buttons">
        {currentQuestion.options.map(option =>
          <button
            key={option.id}
            className={`question-button question-button-${option.index}`
              + (correctAnswerSelected || incorrectAnswerSelected ? (option.is_correct ? ' correct' : ' incorrect') : '')
              + (selectedOptionIndex === option.index ? ' selected' : '')}
            onClick={() => handleOptionSelected(option)}>
          { quiz.immediate_feedback && selectedOptionIndex === option.index ?
            <i className={optionIcon(option)} /> : null
          }
            { option.text }
          </button>
        )}
        </div>

        <div className="answer-explanation">
        {
          quiz.explain_answers && correctAnswerSelected ?
          <div className="answer-explanation-text">{ currentQuestion.correct_answer_explanation }</div>
          : quiz.explain_answers && incorrectAnswerSelected ?
          <div className="answer-explanation-text">{ currentQuestion.incorrect_answer_explanation }</div>
          : <div className="answer-explanation-text"></div>
        }
        { selectedOptionIndex !== null ?
          <div onClick={handleNextButtonClick} className="next-button">
            Next <i className="fas fa-chevron-right" />
          </div> : null
        }
        </div>

        <div className="question-footer">
        { quiz.show_question_footnotes ?
          <div className="question-footer-text">
            { currentQuestion.footnote }
          </div> : null
        }
        </div>
      </div>
      { showReloadPage ?
        <div className="flex inactivity-countdown">
          <div className="inactivity-backdrop"></div>
          <div className="flex inactivity-countdown-content">
            <div className="inactivity-page-reload-text">
              <div>
                Quiz session timed out. Please start over.
              </div>
            </div>
            <div className="inactivity-countdown-actions">
              <div className="inactivity-countdown-action">
                <button className="inactivity-button" onClick={closeReloadPage}>Cancel</button>
              </div>
              <div className="inactivity-countdown-action">
                <button className="inactivity-button" onClick={confirmReloadPage}>OK</button>
              </div>
            </div>
          </div>
        </div> : null
      }
    </div>
  );
}

export default QuizQuestions
