import lwAjax from 'utilities/lwAjax';

export default function QuizService(initialQuizData) {

  function saveData(quizData, playerLevelList) {
    // Unlike all the other associations, playerLevels must be "cleaned" before submittal because
    // they are just a json column on the quizzes table, and the params sent back will not be subject to
    // strong parameter sanitization.  This is also why we need these extra fields in the first place
    // because we can't just rely on an active record id.
    const cleanedPlayerLevelList = playerLevelList.filter(l => !l.markedForDelete).map((level) => {
      delete level.newLevel;
      delete level.index;
      return level;
    });
    const body = JSON.stringify({
      quiz: { ...quizData, player_levels: cleanedPlayerLevelList }
    });
    var method = initialQuizData.id ? 'PUT' : 'POST'
    return lwAjax(method, `/quizzes/${initialQuizData.id || ''}`, handleQuizUpdate, body);
  }
  // ------------------------ PRIVATE START ------------------------
  function handleQuizUpdate(data) {
    // handle resolution logic in caller
    return data;
  }
  // ------------------------ PRIVATE END ------------------------

  return {
    saveData
  }
}
