import lwAjax from 'utilities/lwAjax';
import { IgnoredProperties } from 'utilities/constants';

export default function QuizSurveyQuestionAndAnswerService(initialSurveyQuestionsList) {

  function saveData(preSubmitQuestionsList, quizId) {
    const questionsToSubmit = preSubmitQuestionsList.filter(isNewModifiedOrMarkedForDelete);
    const promises = questionsToSubmit.map((question) => {
      const action = isNew(question) ? 'POST' : question.markedForDelete ? 'DELETE' : 'PUT';
      const body = JSON.stringify({survey_question: question});
      const questionId = question.id || '';
      return lwAjax(action, `/quizzes/${quizId}/quiz_survey_questions/${questionId}`, handleCreateOrUpdate, body);
    });
    return Promise.all(promises);
  }

  // ------------------------ PRIVATE START ------------------------
  function handleCreateOrUpdate(data) {
    // handle resolution logic in caller
    return data;
  }

  function isNewModifiedOrMarkedForDelete(question) {
    return isNew(question) || question.markedForDelete || isQuestionModified(question);
  }

  function isNew(question) { return !question.id; }

  function isQuestionModified(question) {
    const targetQuestionInitialValue = initialSurveyQuestionsList.find(sq => sq.id === question.id);
    // Iterate over every key/value pair in the initial copy of the question stored when the service initialized
    // and compare them with the key/value pairs in the passed in question at the time of submit.
    // Return true if not every one is equal.
    return !Object.entries(targetQuestionInitialValue).every(([questionProperty, initialValue]) => {
      if (IgnoredProperties.includes(questionProperty)) { return true; }
      if (questionProperty === 'answers') {
        // Doing a simple length and equality by id check on the answers here.  Any differences will mark
        // a question for update, and the specifics of that update (including the creation/deletion of answers)
        // will be handled on the back end.
        return question.answers.length === initialValue.length && areAnswersEqual(question.answers, initialValue);
      } else {
        return question[questionProperty] === initialValue;
      }
    });
  }

  function areAnswersEqual(currentAnswers, initialAnswers) {
    // Iterate over all answers in the state they will be submitted in and compare against
    // the equivalent answer (by 'id') in  the initial answers array.  If any answer has changed
    // in any way, we mark the question for update.
    return currentAnswers.every((currentAnswer) => {
      const initial = initialAnswers.find(a => a.id === currentAnswer.id);
      return Object.entries(currentAnswer).every(([answerProperty, currentValue]) => {
        if (IgnoredProperties.includes(answerProperty)) { return true; }
        return initial[answerProperty] === currentValue;
      });
    });
  }
  // ------------------------ PRIVATE END ------------------------

  return {
    saveData
  }
}
