function SharedMetatagsBuilder(sharedMetadata) {
  const currentSharedMetadata = sharedMetadata;

  function isFacebookDataFilled() {
    return currentSharedMetadata.facebook.title && currentSharedMetadata.facebook.title.length > 0;
  }

  function isTwitterDataFilled() {
    return currentSharedMetadata.twitter.title && currentSharedMetadata.twitter.title.length > 0;
  }

  return {
    buildSharedMetatags: function () {
      if (!currentSharedMetadata.shared_url) { return ""; }

      let facebookMetatags = "";
      if (isFacebookDataFilled()) {
        facebookMetatags = `<!-- Facebook -->
<meta property="og:url" content="${currentSharedMetadata.shared_url || ''}"/>
<meta property="og:type" content="website"/>
<meta property="og:title" content="${currentSharedMetadata.facebook.title || ''}"/>
<meta property="og:description" content="${currentSharedMetadata.facebook.description || ''}"/>
`;
        if (currentSharedMetadata.facebook.image_url) {
          facebookMetatags += `<meta property="og:image" content="${currentSharedMetadata.facebook.image_url || ''}">
`
        }
      }

      let twitterMetatags = "";
      if (isTwitterDataFilled()) {
        twitterMetatags = `<!-- Twitter -->
<meta property="og:url" content="${currentSharedMetadata.shared_url || ''}"/>
<meta name="twitter:title" content="${currentSharedMetadata.twitter.title || ''}">
<meta name="twitter:description" content="${currentSharedMetadata.twitter.description || ''}"/>
`;
        if (currentSharedMetadata.twitter.image_url) {
          twitterMetatags += `<meta name="twitter:card" content="summary_large_image"/>
<meta name="twitter:image" content="${currentSharedMetadata.twitter.image_url || ''}"/>
`
        }
      }
      return `${facebookMetatags}${twitterMetatags}`;
    }
  }
}

export default SharedMetatagsBuilder;
