import React, { useState } from 'react';
import useFetch from 'hooks/useFetch';
import ahoy from "ahoy.js";
window.ahoy = ahoy;

ahoy.trackClicks(".tracked-link");

function CustomFinalResults(props) {
  const {
    quiz,
    activePlayer
  } = props;

  const [customHTML, setCustomHTML] = useState({__html: ''});

  useFetch('GET', `/${quiz.obfuscated_id}/players/${activePlayer.id}/final_results_data.json`, handleCustomData);

  // BAD_DATA_HACK
  // This will attempt to replace all tokens with their respective values. In the absence of data for
  // any token, an empty string will be used.
  function handleCustomData(data) {
    if (!data.error) {
      const levelName = (data.level_data || {}).level_name || '';
      const surveyLevelHeader = (data.level_data || {}).survey_level_header || '';
      const customTokensReplaced = (quiz.custom_final_results_html || '').replace(/\[:PLAYER_LEVEL_NAME\]/g, levelName)
                                                                         .replace(/\[:PLAYER_LEVEL_HEADER\]/g, surveyLevelHeader)
                                                                         .replace(/\[:PLAYER_NAME\]/g, activePlayer.display_name)
                                                                         .replace(/\[:TOTAL_QUESTION_COUNT\]/g, data.quiz_score.total_response_count)
                                                                         .replace(/\[:CORRECT_ANSWER_COUNT\]/g, data.quiz_score.correct_response_count)
                                                                         .replace(/\[:ANSWER_WEIGHT_SUM\]/g, data.quiz_score.response_weight_sum)
                                                                         .replace(/\[:ANSWER_WEIGHT_MAX\]/g, data.quiz_score.response_weight_max)
                                                                         .replace(/\[:UTM_PARAMS\]/g, data.utm_params.full_utm_params)
                                                                         .replace(/\[:UTM_SOURCE\]/g, data.utm_params.utm_source)
                                                                         .replace(/\[:UTM_MEDIUM\]/g, data.utm_params.utm_medium)
                                                                         .replace(/\[:UTM_CAMPAIGN\]/g, data.utm_params.utm_campaign)
                                                                         .replace(/\[:UTM_CONTENT\]/g, data.utm_params.utm_content)
                                                                         .replace(/\[:UTM_TERM\]/g, data.utm_params.utm_term);                                                                        ;

      setCustomHTML({__html: customTokensReplaced});
    }
  }

  return (
    <div className="custom-final-results" dangerouslySetInnerHTML={customHTML} />
  );
}

export default CustomFinalResults
