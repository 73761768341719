export const NullableQuizFields = [
  'inactivity_timeout',
  'jot_form_id',
  'jot_form_api_key',
  'custom_final_results_html',
  'custom_get_started_html',
  'ga_enabled',
  'ga_tracking_id',
  'id',
  'created_at',
  'updated_at',
  'admin_id',
  'access_code',
  'social_sharing_enabled',
  'external_id'
]

// This could be made into an object with the keys defining
// specific object types with certain keys that are or are not
// ignored in other objects.  This should eventually replace the need
// for the builderable module on the back end.
export const IgnoredProperties = [
  'created_at',
  'updated_at'
]
