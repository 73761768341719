import React from 'react';

function FrameAncestorsInput(props) {
  const {
    handleFrameAncestorChange,
    deleteFrameAncestor,
    currentFrameAncestors
  } = props;

  return (
    <div className="frame-ancestors-input">
    { currentFrameAncestors.map((frameAncestor, i) =>
      <div key={i}>
        <input
          placeholder="https://www.example.com/"
          name={`frame_ancestor_${i}`}
          type="text"
          onChange={handleFrameAncestorChange}
          value={frameAncestor}
        />
        <i title="remove frame ancestors" className="fas fa-trash-alt" id={`frame_ancestor_${i}`} onClick={deleteFrameAncestor}></i>
      </div>
    )}
    </div>
  );
}

export default FrameAncestorsInput
