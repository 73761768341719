import React, { useState } from 'react';

function QuizPreview(props) {
  const {
    quizData,

    playerLevelList,
    makePlayerLevelEditable,
    deletePlayerLevel,

    preferencesList,
    makePreferenceEditable,
    deletePreference,

    surveyQuestionsList,
    makeSurveyQuestionEditable,
    deleteSurveyQuestion,

    questionsList,
    makeQuizQuestionEditable,
    deleteQuizQuestion
  } = props;

  // this is where the indices are modified...

  function conditionalQuizAssociationClasses(association) {
    const classes = [];
    if (association.markedForDelete) { classes.push('marked-for-delete') }
    if (!association.id || typeof(association.id) !== 'number') { classes.push('new-record') }
    return classes.length ? ` ${classes.join(" ")}` : "";
  }

  function conditionalPlayerLevelClasses(level) {
    // need a separate function for player level classes because they don't have ids
    const classes = [];
    if (level.markedForDelete) { classes.push('marked-for-delete') }
    if (level.newLevel) { classes.push('new-record') }
    return classes.length ? ` ${classes.join(" ")}` : "";
  }

  return (
    <fieldset className="quiz-preview">
      <legend>Quiz Preview</legend>
      { questionsList.length > 0 ? (
      <div className="pre-save-view">
        <div className="psv-quiz-settings">
          <div>
            <span className="qs-field">Name:</span> {quizData.name}
          </div>
          { quizData.sections.includes('attractScreen') && quizData.access_code ?
          <div>
            <span className="qs-field">Access Code:</span> {quizData.access_code}
          </div>
          : null
          }
          <div>
            <span className="qs-field">Timeout:</span> <span className="qs-value timeout">{quizData.inactivity_timeout}</span>
          </div>
        </div>

        <fieldset className="questions-list">
          <legend>Questions</legend>
        {questionsList.sort((a, b) => a.index - b.index).map((q, i) =>
          <div className={`ql-question${conditionalQuizAssociationClasses(q)}`} key={q.text}>
            <div className="ql-question-ie-container">
              <div className="ql-question-i"></div>
              <div className="action-buttons">
                <button className="ql-question-action" onClick={() => makeQuizQuestionEditable(q)}>
                  Edit
                </button>
                <button className="ql-question-action" onClick={() => deleteQuizQuestion(q)}>
                  Delete
                </button>
              </div>
            </div>

            <div className="ql-question-data">
              <div className="ql-question-text">
                {q.text}
              </div>
              <div className="ql-options">
              {q.options.sort((a, b) => a.index - b.index).map((o, i) =>
                <div className="ql-option-wrapper" key={i}>
                  <div className="ql-option">
                    <div className="text">{o.text}</div>
                    {o.is_correct ? <div>(&#10003;)</div> : null}
                  </div>
                  <div className="ql-option-explanation">
                    {o.is_correct ? q.correct_answer_explanation : q.incorrect_answer_explanation}
                  </div>
                </div>
              )}
              </div>
            {q.footnote ?
              <div className="ql-footnote">
                {q.footnote}
              </div> : null
            }
            </div>
          </div>
        )}
        </fieldset>

        { quizData.sections.includes('quizPreferences') ? (
        <fieldset className="preferences-list">
          <legend>Player Contact Info &amp; Preferences</legend>
        { preferencesList.sort((a, b) => a.index - b.index).map((p, i) =>
          <div className={`ql-preference${conditionalQuizAssociationClasses(p)}`} key={p.text}>
            <div className="ql-preference-i-container">
              <div className="ql-preference-i"></div>
              <div className="action-buttons">
                <button onClick={() => makePreferenceEditable(p)}>
                  Edit
                </button>
                <button onClick={() => deletePreference(p)}>
                  Delete
                </button>
              </div>
            </div>

            <div className="ql-preference-data">
              <div className="ql-preference-text">
                {p.text}
              </div>
              { p.show_leaderboard === true ?
              <div className="ql-preference-text">
                “Leaderboard Opt-In” preference
              </div>
              : null }
            </div>
          </div>
        )}
        </fieldset>
        ) : null
        }

        <fieldset className="player-levels-list">
          <legend>Player Score Tiers</legend>
        {playerLevelList.map((level, i) =>
          <div className={`player-level${conditionalPlayerLevelClasses(level)}`} key={level.level_name}>
            <div className="action-buttons">
              <button onClick={() => makePlayerLevelEditable(level, i)}>
                Edit
              </button>
              <button onClick={() => deletePlayerLevel(level, i)}>
                Delete
              </button>
            </div>
            <div className="player-level-data">
              <div className="level-name">
                <label>Name:&nbsp;</label>
                {level.level_name}
              </div>
              <div className="range">
                <label>Range:&nbsp;</label>
                {level.lower} to {level.upper}
              </div>
              <div className="survey-level-header">
                <label>Survey Header:&nbsp;</label>
                {level.survey_level_header}
              </div>
            </div>
          </div>
        )}
        </fieldset>

        { quizData.sections.includes('survey') ? (
        <fieldset className="survey-questions-list">
        <legend>Survey Questions</legend>
        {surveyQuestionsList.map((question, i) =>
          <div className={`survey-question${conditionalQuizAssociationClasses(question)}`} key={question.text}>
            <div className="action-buttons">
              <button onClick={() => makeSurveyQuestionEditable(question)}>
                Edit
              </button>
              <button onClick={() => deleteSurveyQuestion(question)}>
                Delete
              </button>
            </div>

            <div className="survey-question-content">
              <div className="survey-question-text">
                { question.text }
              </div>
              <div className="survey-answers-list">
              {question.answers.sort((a, b) => { a.index - b.index }).map((answer) =>
                <div key={answer.text} className="survey-answer-text">
                  { answer.text }
                </div>
              )}
              </div>
            </div>
          </div>
        )}
        </fieldset>
        ) : null
        }

      </div>
    ) : (
      <div className="pre-save-view">
        <div className="input-detail">
          Quizzes must have at least one question.  Please add a question to save.
        </div>
      </div>
    )}
    </fieldset>
  );
}

export default QuizPreview
