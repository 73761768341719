import React from 'react';

function QuizSurveyAnswerInputs(props) {
  const {
    answer,
    deleteAnswer,
    handleQuizAnswerInputChange
  } = props;

  return (
    <div className="survey-answer">
      <label>Answer: { answer.index + 1 }</label>
      <input
        id={`input_${answer.index}`}
        name="text"
        onChange={handleQuizAnswerInputChange}
        value={answer.text || ''}
      />
      <i className="fas fa-trash-alt" onClick={() => deleteAnswer(answer.index)} ></i>
    </div>
  );
}

export default QuizSurveyAnswerInputs
