import React, { useState, useEffect } from 'react';
import lwAjax from 'utilities/lwAjax';

function AttractScreen(props) {
  const {
    transitionToNextSection,
    quizId,
    recordEvent
  } = props;
  const [accessCode, setAccessCode] = useState("");
  const [invalidCode, setInvalidCode] = useState(false);

  // This event is recorded before the player is created, but will belong to the view, which will get linked to a player once created
  useEffect(() => { recordEvent('attract_screen_viewed') }, []);

  function handleValidAccessCode() {
    setInvalidCode(false);
    transitionToNextSection();
  }

  function validateAccessCode() {
    const body = JSON.stringify({access_code: accessCode});
    lwAjax('POST', `/api/v1/validate_access_code/${quizId}`, handleAccessCodeValidation, body).catch((error) => {
      console.error('Error:', error);
      setInvalidCode(true);
    });
  }

  function handleAccessCodeValidation(data) {
    if (data.valid) {
      console.log('Success:', data);
      handleValidAccessCode();
    } else {
      console.log('Error:', data);
      setInvalidCode(true);
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    validateAccessCode();
  }

  function handleChange(e) {
    setAccessCode(e.target.value);
  }

  return (
    <div className="flex attract-screen">
      <div className="flex attract-screen-content">
        <div className="prompt">
          Test Your Knowledge<br/>With Our Quiz
        </div>
        <div className="access-code-form">
          <form onSubmit={handleSubmit}>

            <div className="access-code-input-column">
              <div className="instructions">
                Enter the Access Code to Play
                { invalidCode ? <div className="error-message">Invalid Code</div> : null }
              </div>
              <input className="access-code-input" type="text" name="accessCode" value={accessCode} onChange={handleChange} placeholder="Game Access Code" />
            </div>
            <button type="submit" className="access-code-submit">Go!</button>
          </form>
        </div>
      </div>
      <div className="disclaimer">
        <span className="row">Use this footer for disclaimers or footnotes </span>
        <span className="row">to identify the source of your game data, </span>
        <span className="row">or to communicate terms and conditions.</span>
      </div>
    </div>
  );
}

export default AttractScreen
