import React, { useState, useEffect } from 'react';
import AttractScreen from './AttractScreen';
import GetStartedScreen from './GetStartedScreen';
import QuizQuestions from './QuizQuestions';
import InactivityCountdown from './InactivityCountdown';
import QuizPreferences from './QuizPreferences';
import LwJotForm from './LwJotForm';
import AdminMenu from '../AdminMenu';
import Survey from './Survey';
import FinalResults from './FinalResults';
import CustomFinalResults from './CustomFinalResults';
import CustomGetStartedScreen from './CustomGetStartedScreen';
import useLwAjaxChain from 'hooks/useLwAjaxChain';
import lwAjax from 'utilities/lwAjax';
import ahoy from "ahoy.js";


function QuizGameRunner(props) {
  const {
    quiz,
    sessionId
  } = props;

  const [finalInactivityCountdownInitiated, setFinalInactivityCountdownInitiated] = useState(false);
  const [inactivityTimeoutId, setInactivityTimeoutId] = useState();
  const inactivityTimeout = quiz.inactivity_timeout ? quiz.inactivity_timeout * 1000 : null;
  const [getStartedScreenTimeoutMS] = useState(inactivityTimeout);
  const [quizQuestionTimeoutMS] = useState(inactivityTimeout);

  const [activePlayer, setActivePlayer] = useState();
  const [currentScreen, setCurrentScreen] = useState();
  const [quizBackground, setQuizBackground] = useState({});
  const [playerCreated, setPlayerCreated] = useState(false);

  useEffect(saveSessionId, []);
  function saveSessionId() {
    if (sessionId && sessionId.length > 0) {
      sessionStorage.setItem('sessionId', sessionId)
    }
  }

  useEffect(loadBackgroundImage, []);
  function loadBackgroundImage() {
    if (quiz.background_image_url) {
      const bgi = new Image();
      bgi.src = quiz.background_image_url;
      bgi.onload = function() {
        setQuizBackground({
          backgroundImage: `url(${quiz.background_image_url})`
        });
      };
    } else {
      setQuizBackground({
        background: 'grey'
      });
    }
  }

  // onLoadedCssMapUpdate will run when loaddedCssMap is initialized and then every time one of the
  // CSS files' onload function runs and updates the loaddedCssMap for that file's id.  When
  // the last one is set to true the conditional in onLoadedCssMapUpdate will return true and the
  // game will be initialized with the first section.
  useEffect(onLoadedCssMapUpdate, [loaddedCssMap]);
  const [loaddedCssMap, setLoaddedCssMap] = useState({});
  useEffect(loadCustomCss, []);
  function loadCustomCss() {
    if (!quiz.custom_css_files.length) {
      setCurrentScreen(quiz.sections[0]);
    } else {
      // initialize loadedCss as a mab of custom_css_file ids
      // and a boolean to indicate whether they've been loaded or not
      setLoaddedCssMap(quiz.custom_css_files.reduce((acc, file) => {
        acc[file.id] = false;
        return acc;
      }, {}));
      // Create a stylesheet link tag for every active custom css file associated with this quiz
      // and set its onload function to update the loaddedCssMap when that file is loaded.
      quiz.custom_css_files.sort((fileA, fileB) => fileA.id - fileB.id).forEach((file) => {
        let cssLink = document.createElement("link");
        cssLink.rel = 'stylesheet';
        cssLink.type = 'text/css';
        cssLink.href = file.url;
        cssLink.onload = function() {
          setLoaddedCssMap({...loaddedCssMap, [file.id]: true});
        }
        document.getElementsByTagName('head')[0].appendChild(cssLink);
      });
    }
  }

  function onLoadedCssMapUpdate() {
    const allCssLoaded = Object.entries(loaddedCssMap).every((cssFileId, cssLoaded) => cssLoaded);
    if (allCssLoaded) { setCurrentScreen(quiz.sections[0]); }
  }

  // This will reset the inactivity timeout every time a state is transitioned to that we have defined a timeout for.
  useEffect(initInactivityTimeout, [currentScreen]);
  function initInactivityTimeout() {
    clearTimeout(inactivityTimeoutId);
    const timeoutMS = getInactivityTimeoutMS();
    if (timeoutMS) {
      const timeoutId = setTimeout(()=>{
        transitionToInactivityCountdown();
      }, timeoutMS);
      setInactivityTimeoutId(timeoutId);
    }
  }

  useLwAjaxChain(
    [
      // Always sign the player out when QuizGameRunner initializes. This lived in the AttractScreen component
      // before quiz sections became toggleable.
      {
        method: 'GET',
        url: '/players/sign_out.json',
        success: initialPlayerSignoutSuccess
      },
      // Only create a player when QuizGameRunner initializes if the quiz doesn't include the GetStartedScreen.
      // This call can not be run until the call to /players/sign_out.json resolves (see note on delay arg below),
      // or the current_player will not be set on the session, and no responses/scores will be recorded.
      {
        method: 'POST',
        url: '/players.json',
        body: JSON.stringify({ player: { display_name: '' }, session_id: sessionId }),
        success: handlePlayerCreationQGR,
        onlyRunIf: noGetStartedScreen
      }
    ], {
      // Explicitly passing in empty array even though that is the default value for triggers
      // because it is imperative that a player isn't signed out or recreated in the middle of a quiz.
      triggers: [],
      // It seems that there may be a limitation to how quickly a session can be destroyed/created somewhere
      // in devise or warden or rack...so even guaranteeing that the sign_in call is deferred until after
      // sign_out completes isn't enough, and we need to add a delay of (at least) one second between the calls.
      // It should be noted that it doesn't matter how long a user waits after the sign_in call completes.
      // Even waiting 10s of seconds after will still result in a current_player of nil if the sign_in call
      // happens too quickly after the sign_out call.
      delay: noGetStartedScreen() ? 1000 : 0
    }
  );

  function initialPlayerSignoutSuccess(data) {
    console.log("initialPlayerSignoutSuccess --> ", data);
  }

  function handlePlayerCreationQGR(data) {
    console.log("handlePlayerCreationQGR --> ", data);
    setActivePlayer(data);
    setPlayerCreated(true);
    sessionStorage.setItem('activePlayerId', data.id)
  }

  function noGetStartedScreen() {
    return !quiz.sections.includes('getStartedScreen');
  }

  function transitionToInactivityCountdown() {
    setFinalInactivityCountdownInitiated(true);
    clearTimeout(inactivityTimeoutId);
  }

  function getInactivityTimeoutMS() {
    return {
      "getStartedScreen": getStartedScreenTimeoutMS,
      "quizQuestions": quizQuestionTimeoutMS
    }[currentScreen];
  }

  function transitionToNextSection() {
    const nextIndex = quiz.sections.indexOf(currentScreen) + 1;
    const nextSection = quiz.sections[nextIndex];
    if (nextSection) {
      setCurrentScreen(nextSection);
    } else {
      resetGameState();
    }
  }

  function resetGameState() {
    // When a player finishes a game simply reload the current quiz.  This ensures the previous game's state is wiped out.
    window.location.href = `/${quiz.obfuscated_id}/run`;
  }

  function isLastSection(section) {
    return quiz.sections.indexOf(section) === quiz.sections.length - 1;
  }

  function recordEvent(name) {
    const quizId = { quiz_id: quiz.id };
    const properties = {
      ...quizId,
      section: currentScreen
    }
    ahoy.track(name, properties);
  }

  return (
    <div className="quiz-game-index quiz-image-background" style={quizBackground}>
      {
        currentScreen === 'attractScreen' ?
          <AttractScreen
            transitionToNextSection={transitionToNextSection}
            quizId={quiz.id}
            recordEvent={recordEvent}
          /> :
        currentScreen === 'getStartedScreen' ?
          <GetStartedScreen
            transitionToNextSection={transitionToNextSection}
            setActivePlayer={setActivePlayer}
            setPlayerCreated={setPlayerCreated}
            recordEvent={recordEvent}
          /> :
        currentScreen === 'customGetStarted' ?
          <CustomGetStartedScreen
            transitionToNextSection={transitionToNextSection}
            customHTML={quiz.custom_get_started_html}
            recordEvent={recordEvent}
          /> :
        currentScreen === 'quizQuestions' ?
          <QuizQuestions
            quiz={quiz}
            initInactivityTimeout={initInactivityTimeout}
            transitionToNextSection={transitionToNextSection}
            transitionToInactivityCountdown={transitionToInactivityCountdown}
            playerCreated={playerCreated}
            recordEvent={recordEvent}
          /> :
        currentScreen === 'quizPreferences' ?
          <QuizPreferences
            activeQuiz={quiz}
            activePlayerId={activePlayer.id}
            transitionToNextSection={transitionToNextSection}
          /> :
        currentScreen === 'survey' ?
          <Survey
            activeQuiz={quiz}
            activePlayer={activePlayer}
            transitionToNextSection={transitionToNextSection}
            isLastSection={isLastSection('survey')}
          /> :
        currentScreen === 'jotForm' ?
          <LwJotForm
            formData={quiz.form_data}
            quizId={quiz.id}
            playerId={activePlayer.id}
            isLastSection={isLastSection('jotForm')}
            transitionToNextSection={transitionToNextSection}
          /> :
        currentScreen === 'finalResults' ?
          <FinalResults
            quiz={quiz}
            playerId={activePlayer.id}
          /> :
        currentScreen === 'customFinalResults' ?
          <CustomFinalResults
            quiz={quiz}
            activePlayer={activePlayer}
          /> : null
      }
      {
        finalInactivityCountdownInitiated ?
          <InactivityCountdown
            setFinalInactivityCountdownInitiated={setFinalInactivityCountdownInitiated}
            initInactivityTimeout={initInactivityTimeout}
            quizId={quiz.id}
          /> : null
      }
      <AdminMenu
        page='QuizGameRunner'
      />
    </div>
  );
}

export default QuizGameRunner
