import React, { useState, useEffect } from 'react';
function SurveyAnswer(props) {
  const {
    answer,
    handleAnswerSelect,
    isSelected,
    questionType
  } = props;

  const [surveyAnswerClass, setSurveyAnswerClass] = useState('');

  useEffect(() => {
    setSurveyAnswerClass(isSelected ? 'is-selected' : '');
  }, [isSelected]);

  return ( questionType === 'buttons' ? (
    <div
      id={answer.id}
      className={`survey-answer buttons ${surveyAnswerClass}`}
      onClick={handleAnswerSelect}>
      { answer.text }
    </div>
  ) : (
    <div className={'survey-answer checkboxes'}>
      <input type="checkbox" id={answer.id} className={`survey-answer ${surveyAnswerClass}`} onClick={handleAnswerSelect} />
      <label htmlFor={answer.id}>{ answer.text }</label>
    </div>
    ));
}

export default SurveyAnswer
