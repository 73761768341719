import React from 'react';

function CustomGetStartedInputs(props) {
  const {
    currentCustomGetStartedHtml,
    setCurrentCustomGetStartedHtml
  } = props;

  return (
    <div className="custom-get-started-inputs">
      <div className="label-and-input-hints">
        <label>Custom Page HTML:</label>
        <div className="input-hints">
          <div>Required.</div>
          <div>No length limit.</div>
          <div>Must be valid HTML.</div>
        </div>
      </div>
      <textarea
        onChange={(e) => setCurrentCustomGetStartedHtml(e.currentTarget.value)}
        value={currentCustomGetStartedHtml || ''}
      ></textarea>
    </div>
  );
}

export default CustomGetStartedInputs
