import React, { useState } from 'react';
import FrameAncestorsInput from './FrameAncestorsInput';

import { NullableQuizFields } from '../../utilities/constants';

function QuizInputs(props) {
  const {
    quizData,
    setQuizData,
    currentQuizQuestionAndOptionData,
    setCurrentQuizQuestionAndOptionData,
  } = props;

  function handleQuizInputChange(e) {
    const [propName, propVal] = sanitizeInput(e);
    setQuizData({
      ...quizData,
      [propName]: propVal
    });
  }

  function isWeightedQuiz() {
    return quizData.type === 'WeightedQuiz';
  }

  function sanitizeInput(e) {
    // react inputs don't like null values...so we do it maually.
    if (NullableQuizFields.includes(e.currentTarget.name)) {
      const val = e.currentTarget.value === '' ? null : e.currentTarget.value;
      return [e.currentTarget.name, val];
    } else {
      return [e.currentTarget.name, e.currentTarget.value];
    }
  }

  function handleQuizCheckboxChange(e) {
    // Build k/v pair for update using the input's name, and checked value.
    let newData = {[e.currentTarget.name]: e.currentTarget.checked};

    if (e.currentTarget.name === 'immediate_feedback') {
      if (!e.currentTarget.checked) {
        // If the user is unchecking immediate_feedback, we have to also ensure that explain_answers
        // gets unchecked as well since it can only be true for quizzes that have immediate_feedback enabled.
        newData = {
          immediate_feedback: false,
          explain_answers: false
        }
      }
    }

    setQuizData({ ...quizData, ...newData});
  }

  function handleQuizTypeChange(e) {
    // If WeightedQuiz is selected, update sections to include customFinalResults
    // We want finalResults & customFinalResults to both be checked, but only customFinalResults should be set in the sections
    if (e.currentTarget.value === 'WeightedQuiz') {
      let updatedSectionsList;

      // Remove the customFinalResults parent from sections
      updatedSectionsList = [...quizData.sections, 'customFinalResults'].
        filter(sectionName => sectionName !== specialSectionsMap['customFinalResults'].parent).
        sort(ensureSectionOrder);

      // Dedupe sections list in case customFinalResults was selected earlier
      setQuizData({...quizData, ...{
        [e.currentTarget.name]: e.currentTarget.value,
        sections: Array.from(new Set(updatedSectionsList))
      }});
    } else {
      // If it's not a WeightedQuiz, we only need to update the type, no need to mess with the sections
      setQuizData({
        ...quizData,
        [e.currentTarget.name]: e.currentTarget.value
      });
    }

    setCurrentQuizQuestionAndOptionData({
      ...currentQuizQuestionAndOptionData,
      type: e.currentTarget.value
    })
  }

  function handleQuizSectionCheckboxChange(e) {
    let updatedSectionsList;
    if (e.currentTarget.checked) {
      updatedSectionsList = [...quizData.sections, e.currentTarget.name].sort(ensureSectionOrder);
    } else {
      updatedSectionsList = quizData.sections.filter(sectionName => sectionName !== e.currentTarget.name);
    }
    setQuizData({...quizData, ...{sections: updatedSectionsList}});
  }

  // This map could be simplified, but given the complexity of the handleSpecialSectionCheckboxChange
  // function, the readability is worth the convenience props
  const specialSectionsMap = {
    customGetStarted: {
      isParent: false,
      isChild: true,
      parent: 'getStartedScreen'
    },
    getStartedScreen: {
      isParent: true,
      isChild: false,
      child: 'customGetStarted'
    },
    customFinalResults: {
      isParent: false,
      isChild: true,
      parent: 'finalResults'
    },
    finalResults: {
      isParent: true,
      isChild: false,
      child: 'customFinalResults'
    }
  }
  function handleSpecialSectionCheckboxChange(e) {
    // For a WeightedQuiz, we handle the section settings in handleQuizTypeChange,
    // so just bail here when those sections are clicked
    if (isWeightedQuiz() && (e.currentTarget.name === "finalResults" || e.currentTarget.name === "customFinalResults")) {
      return e.preventDefault();
    }

    let updatedSectionsList;
    if (e.currentTarget.checked) {
      // if a sub section is checked:
      if (specialSectionsMap[e.currentTarget.name].isChild) {
        // sub section must be added to list
        updatedSectionsList = [...quizData.sections, e.currentTarget.name].filter((section) => {
          // parent must be removed from the list (but appear checked in the ui)
          return section !== specialSectionsMap[e.currentTarget.name].parent;
        }).sort(ensureSectionOrder);
      } else {
      // if a parent section is checked:
        // parent added to list
        updatedSectionsList = [...quizData.sections, e.currentTarget.name].sort(ensureSectionOrder);
      }
    } else {
      // if a sub section is unchecked:
      if (specialSectionsMap[e.currentTarget.name].isChild) {
        // its parent must be added back to the list
        updatedSectionsList = [...quizData.sections, specialSectionsMap[e.currentTarget.name].parent].filter((section) => {
          // the sub section must be removed from the list
          return section !== e.currentTarget.name;
        }).sort(ensureSectionOrder);
      } else {
      // if a parent is unchecked:
        updatedSectionsList = quizData.sections.filter((sectionName) => {
        // the parent and child must be removed
          return sectionName !== e.currentTarget.name && sectionName !== specialSectionsMap[e.currentTarget.name].child;
        });
      }
    }
    setQuizData({...quizData, ...{sections: Array.from(new Set(updatedSectionsList))}});
  }

  function handleQuizOptionCheckboxChange(e) {
    let updatedOptionsList;
    if (e.currentTarget.checked) {
      // Order doesn't matter with options.  Templates are only looking for inclusion.
      updatedOptionsList = [...quizData.options, e.currentTarget.name]
    } else {
      updatedOptionsList = quizData.options.filter(optionName => optionName !== e.currentTarget.name);
    }
    setQuizData({...quizData, ...{options: updatedOptionsList}});
  }

  function handleFrameAncestorChange(e) {
    const targetIndex = +e.currentTarget.name.match(/\d+/)[0];

    quizData.frame_ancestors.splice(targetIndex, 1, e.currentTarget.value);
    setQuizData({...quizData, frame_ancestors: quizData.frame_ancestors});
  }

  function addFrameAncestor() {
    setQuizData({...quizData, frame_ancestors: [...quizData.frame_ancestors, '']});
  }

  function deleteFrameAncestor(e) {
    const targetIndex = +e.currentTarget.id.match(/\d+/)[0];

    quizData.frame_ancestors.splice(targetIndex, 1);
    setQuizData({...quizData, frame_ancestors: quizData.frame_ancestors});
  }

  // Currently there is no way to run the various quiz sections in any order except the following.
  // This is what will be used to enforce that order in the updates to quiz objects
  const quizSectionOrder = {
    'attractScreen': 1,
    'getStartedScreen': 2,
    'customGetStarted': 3,
    'quizQuestions': 4,
    'quizPreferences': 5,
    'survey': 6,
    'jotForm': 7,
    'finalResults': 8,
    'customFinalResults': 9
  }

  function ensureSectionOrder(sectionA, sectionB) {
    return quizSectionOrder[sectionA] - quizSectionOrder[sectionB];
  }

  function sectionSelected(sectionName) {
    return quizData.sections.includes(sectionName);
  }

  function optionSelected(optionName) {
    return quizData.options.includes(optionName);
  }

  return (
    <fieldset className="quiz-settings">
    <legend>Quiz Settings</legend>

      <div className="qs-setting">
        <label htmlFor="quiz-name-input">Quiz Name:</label>
        <input
          id="quiz-name-input"
          name="name"
          type="text"
          onChange={handleQuizInputChange}
          value={quizData.name || ''}
        />
        <div className="qs-input-detail">Required. Up to 40 characters.</div>
      </div>

      <div className="qs-setting external-id">
        <label htmlFor="external-id-input">Quiz URL:&nbsp;</label>
        <input
          id="external-id-input"
          name="external_id"
          type="text"
          onChange={handleQuizInputChange}
          value={quizData.external_id || ''}
          placeholder="customize"
        />
        { quizData.external_id ?
        <div className="qs-input-detail">Custom URL:&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={quizData.admin_id ? (window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/quiz/' + quizData.admin_id + '/' + quizData.external_id) : null}
          >{`${window.location.protocol}//${window.location.hostname}${window.location.port ? ":"+window.location.port : ''}/quiz/${quizData.admin_id ? quizData.admin_id : ' ⋯ '}/${quizData.external_id}`}</a>
        </div>
        :
        <div className="qs-input-detail">Default URL:&nbsp;
          <a
            target="_blank"
            rel="noopener noreferrer"
            href={quizData.obfuscated_id ? (window.location.protocol + '//' + window.location.hostname + (window.location.port ? ':' + window.location.port : '') + '/' + quizData.obfuscated_id + '/run') : null}
          >{`${window.location.protocol}//${window.location.hostname}${window.location.port ? ":"+window.location.port : ''}/${quizData.obfuscated_id ? quizData.obfuscated_id : ' ⋯ '}/run`}</a>
        </div>
        }
        <div className="qs-input-detail_hint">Use the custom URL as an iframe <code>src</code> so you can swap embedded quizzes without editing the host page.</div>
      </div>

      <div className="qs-setting inactivity-timeout">
        <label htmlFor="quiz-inactivity-timeout-input">Inactivity Timeout:</label>
        <input
          id="quiz-inactivity-timeout-input"
          name="inactivity_timeout"
          type="text"
          onChange={handleQuizInputChange}
          value={quizData.inactivity_timeout || ''}
        />
        <div className="qs-input-detail">(Seconds) Optional: leave blank for no timeout.</div>
      </div>

      <div className="qs-setting quiz-sections">
        <label>Quiz Sections:</label>
        <div className="qs-input-detail">Check all desired sections.</div>

        <div className="qs-section-selector">

          <div className="attract-screen">
            <input
              id="attract-screen-quiz-section-checkbox"
              className="section-select"
              name="attractScreen"
              type="checkbox"
              onChange={handleQuizSectionCheckboxChange}
              checked={sectionSelected('attractScreen')}
            />
            <label
              htmlFor="attract-screen-quiz-section-checkbox"
              className="qs-section-select-label">
              Attract Screen
            </label>
          </div>
          { sectionSelected('attractScreen') ?
          <div className="sub-input access-code">
            <label htmlFor="quiz-access-code-input">Access Code:&nbsp;</label>
            <input
              id="quiz-access-code-input"
              name="access_code"
              type="text"
              onChange={handleQuizInputChange}
              value={quizData.access_code || ''}
            />
            <div className="qs-input-detail">Required if Attract Screen is active</div>
          </div>
          : null }

          <div className="get-started-screen">
            <input
              id="get-started-screen-quiz-section-checkbox"
              className="section-select"
              name="getStartedScreen"
              type="checkbox"
              onChange={handleSpecialSectionCheckboxChange}
              checked={sectionSelected('getStartedScreen') || sectionSelected('customGetStarted')}
            />
            <label
              htmlFor="get-started-screen-quiz-section-checkbox"
              className="qs-section-select-label">Get Started Screen</label>
            <div className="qs-input-detail">Default screen includes player name input.</div>
          </div>

          { sectionSelected('getStartedScreen') || sectionSelected('customGetStarted') ?
          <div className="sub-option custom-get-started-screen">
            <input
              id="custom-get-started-quiz-section-checkbox"
              className="section-select"
              name="customGetStarted"
              type="checkbox"
              onChange={handleSpecialSectionCheckboxChange}
              checked={sectionSelected('customGetStarted')}
            />
            <label
              htmlFor="custom-get-started-quiz-section-checkbox"
              className="qs-section-select-label">
              Custom HTML
            </label>
            <div className="qs-input-detail">Customize via <span className="font-bold">Edit Get Started Screen</span> button below.</div>
          </div>
          : null }

          <div className="quiz-questions">
            <input
              id="quiz-questions-quiz-section-checkbox"
              className="section-select"
              type="checkbox"
              disabled={true}
              checked
            />
            <label
              htmlFor="quiz-questions-quiz-section-checkbox"
              className="qs-section-select-label">
              Quiz Questions
            </label>
          </div>

          <div className="sub-option home-button">
            <input
              id="home-button-quiz-option-checkbox"
              className="option-select"
              name="homeButton"
              type="checkbox"
              onChange={handleQuizOptionCheckboxChange}
              checked={optionSelected('homeButton')}
            />
            <label
              htmlFor="home-button-quiz-option-checkbox"
              className="qo-option-select-label">
              Home Button
            </label>
          </div>

          <div className="sub-option question-timer">
            <input
              id="question-timer-quiz-option-checkbox"
              className="option-select"
              name="questionTimer"
              type="checkbox"
              onChange={handleQuizOptionCheckboxChange}
              checked={optionSelected('questionTimer')}
            />
            <label
              htmlFor="question-timer-quiz-option-checkbox"
              className="qo-option-select-label">
              Question Timer
            </label>
          </div>

          <div className="sub-option question-footnotes">
            <input
              id="question-footnotes-checkbox"
              name="show_question_footnotes"
              className="option-select"
              type="checkbox"
              onChange={handleQuizCheckboxChange}
              checked={quizData.show_question_footnotes}
            />
            <label htmlFor="question-footnotes-checkbox" className="qo-option-select-label">Question Footnotes</label>
            <div className="qs-input-detail">Display configurable text under each question.</div>
          </div>

          {
            // Quiz Type
            // TODO: at some point, we need to decide if type should be editable
          }
          <ul className="sub-option quiz-type">
            <li>
              <div className="">Quiz Type</div>
              <div className="">
                <input
                  id={`weighted-quiz`}
                  type="radio"
                  name="type"
                  value="WeightedQuiz"
                  checked={isWeightedQuiz()}
                  onChange={handleQuizTypeChange}
                />
                <label htmlFor="weighted-quiz" className="qo-option-select-label">Weighted Quiz</label>
                <div className="qs-input-detail">Rate the quiz-taker through questions with weighted answer options.</div>
              </div>
              <div className="">
                <input
                  id={`standard-quiz`}
                  type="radio"
                  name="type"
                  value="StandardQuiz"
                  checked={quizData.type === 'StandardQuiz'}
                  onChange={handleQuizTypeChange}
                />
                <label htmlFor="standard-quiz" className="qo-option-select-label">Standard Quiz</label>
                <div className="qs-input-detail">Final score: questions answered correctly.</div>
                {
                  // StandardQuiz options
                  quizData.type === 'StandardQuiz' ?
                  <div className="sub-option immediate-feedback">
                    <input
                      id="immediate-feedback-checkbox"
                      name="immediate_feedback"
                      className="option-select"
                      type="checkbox"
                      onChange={handleQuizCheckboxChange}
                      checked={quizData.immediate_feedback}
                    />
                    <label htmlFor="immediate-feedback-checkbox" className="qo-option-select-label">Immediate Feedback</label>
                    <div className="qs-input-detail">Confirm each answer with right or wrong.</div>
                  </div> : null
                }

                {
                  // Explain Answers option is only visible when Immediate Feedback is selected
                  quizData.type === 'StandardQuiz' && quizData.immediate_feedback ?
                  <div className="sub-sub-option explain-answers">
                    <input
                      id="explain-answers-checkbox"
                      name="explain_answers"
                      className="option-select"
                      type="checkbox"
                      onChange={handleQuizCheckboxChange}
                      checked={quizData.explain_answers}
                    />
                    <label htmlFor="explain-answers-checkbox" className="qo-option-select-label">Explain Answers</label>
                    <div className="qs-input-detail">Show educational text for each right or wrong answer.</div>
                  </div> : null
                }
              </div>
            </li>
          </ul>

          <div className="player-contact-prefs">
            <input
              id="quiz-preferences-quiz-section-checkbox"
              className="section-select"
              name="quizPreferences"
              type="checkbox"
              onChange={handleQuizSectionCheckboxChange}
              checked={sectionSelected('quizPreferences')}
            />
            <label
              htmlFor="quiz-preferences-quiz-section-checkbox"
              className="qs-section-select-label">Player Contact Info & Preferences</label>
          </div>

          <div className="survey-questions">
            <input
              id="survey-quiz-section-checkbox"
              className="section-select"
              name="survey"
              type="checkbox"
              onChange={handleQuizSectionCheckboxChange}
              checked={sectionSelected('survey')}
            />
            <label
              htmlFor="survey-quiz-section-checkbox"
              className="qs-section-select-label">
              Survey Questions
            </label>
          </div>

          <div className="jotform-integration">
            <input
              id="jotform-quiz-section-checkbox"
              className="section-select"
              name="jotForm"
              type="checkbox"
              onChange={handleQuizSectionCheckboxChange}
              checked={sectionSelected('jotForm')}
            />
            <label
              htmlFor="jotform-quiz-section-checkbox"
              className="qs-section-select-label">
              JotForm Integration
            </label>
            <div className="qs-input-detail">(from JotForm).</div>
          </div>

          { sectionSelected('jotForm') ?
          <div>
            <div className="sub-input jotform-id">
              <label htmlFor="jotform-id-input">Survey ID:&nbsp;</label>
              <input
                id="jotform-id-input"
                name="jot_form_id"
                type="text"
                onChange={handleQuizInputChange}
                value={quizData.jot_form_id || ''}
              />
            </div>
            <div className="sub-input jotform-api-key">
              <label htmlFor="jotform-api-key-input">API Key:&nbsp;</label>
              <input
                id="jotform-api-key-input"
                name="jot_form_api_key"
                type="text"
                onChange={handleQuizInputChange}
                value={quizData.jot_form_api_key || ''}
              />
            </div>
          </div> : null
          }

          {
            // Final Results screen
            // required for the WeightedQuiz type
          }
          <div className="final-results-screen">
            <input
              id="final-results-quiz-section-checkbox"
              className="section-select"
              name="finalResults"
              type="checkbox"
              onChange={handleSpecialSectionCheckboxChange}
              checked={sectionSelected('finalResults') || sectionSelected('customFinalResults')}
            />
            <label
              htmlFor="final-results-quiz-section-checkbox"
              className="qs-section-select-label">
              Final Results Screen
            </label>
            <div className="qs-input-detail">
              A Custom Final Results screen is required for Weighted Quizzes
            </div>
          </div>

          {
            // Custom Final Results HTML
            // required for the WeightedQuiz type
            sectionSelected('finalResults') || sectionSelected('customFinalResults') ?
          <div className="sub-option custom-final-results-screen">
            <input
              id="custom-final-results-quiz-section-checkbox"
              className="section-select"
              name="customFinalResults"
              type="checkbox"
              onChange={handleSpecialSectionCheckboxChange}
              checked={sectionSelected('customFinalResults')}
            />
            <label
              htmlFor="custom-final-results-quiz-section-checkbox"
              className="qs-section-select-label">Custom HTML</label>
              <div className="qs-input-detail">Customize via <span className="font-bold">Edit Final Results Screen</span> button below.</div>
          </div>
          : null }

        </div>
      </div>

      <div className="qs-setting quiz-options">
        <label>Quiz Options:</label>
        <div className="qs-input-detail">Check all desired options.</div>

        <div className="qo-options-selector">

          <div className="sharing-metadata">
            <input
              id="sharing-metadata-checkbox"
              name="social_sharing_enabled"
              className="option-select"
              type="checkbox"
              onChange={handleQuizCheckboxChange}
              checked={quizData.social_sharing_enabled || false}
            />
            <label htmlFor="sharing-metadata-checkbox" className="qo-option-select-label">Sharing Metadata</label>
            <div className="qs-input-detail">Add metadata and images for enhanced Twitter and Facebook social sharing.</div>
          </div>

          <div className="enable-ga">
            <input
              id="enable-ga-checkbox"
              name="ga_enabled"
              className="option-select"
              type="checkbox"
              onChange={handleQuizCheckboxChange}
              checked={quizData.ga_enabled || false}
            />
            <label htmlFor="enable-ga-checkbox" className="qo-option-select-label">Google Analytics</label>
            <div className="qs-input-detail">Activate Google Analytics for this quiz…</div>
          </div>

          {quizData.ga_enabled ?
            <div className="sub-input ga-tracking-id">
            <label htmlFor="ga-tracking-id-input" className="qo-option-select-label">Tracking ID:&nbsp;</label>
              <input
                id="ga-tracking-id-input"
                name="ga_tracking_id"
                type="text"
                onChange={handleQuizInputChange}
                value={quizData.ga_tracking_id || ''}
              />
              <div className="qs-input-detail">(from Google)</div>
            </div> : null
          }

          <div className="frame-ancestors">
            <i title="add frame ancestors" className="fas fa-plus-square" onClick={addFrameAncestor}></i>
            <label>Embedded Quiz Host Site(s):&nbsp;</label>
            <div className="qs-input-detail">Add host urls where this quiz will run in an iframe.
            <span className="embed-hint"><i className="fa fa-question-circle"></i><div className="embed-hint-text">To embed this quiz in your site:<br/>
&nbsp;•&nbsp;Click [+] to add an input, and enter the host URL of your site.<br/>
&nbsp;•&nbsp;On your site, add an iframe with src=(Default or Custom URL from Quiz URL above)</div></span></div>
            { false && quizData.frame_ancestors.length && quizData.frame_ancestors[0] ?
              <div className="sub-input external-id">
              <label htmlFor="external-id-input">Quiz URL:&nbsp;</label>
              <input
              id="external-id-input"
              name="external_id"
              type="text"
              onChange={handleQuizInputChange}
              value={quizData.external_id || ''}
              />

              { quizData.external_id ?
                <div className="qs-input-detail">Quiz Custom URL: <b>{`${window.location.protocol}//${window.location.hostname}${window.location.port ? ":"+window.location.port : null}/quiz/${quizData.admin_id}/${quizData.external_id}`}</b></div>
              :
                <div className="qs-input-detail">(Optional) Create a custom URL for embedded quiz…</div>
              }
              </div>
              : null }
            <FrameAncestorsInput
              handleFrameAncestorChange={handleFrameAncestorChange}
              deleteFrameAncestor={deleteFrameAncestor}
              currentFrameAncestors={quizData.frame_ancestors}
            />
          </div>

        </div>
      </div>
    </fieldset>
  );
}

export default QuizInputs
