// Thank you:
  // https://overreacted.io/making-setinterval-declarative-with-react-hooks/

// The link above explaines this code in gross detail, but the tldr version is:
// A callback passed to setInterval in a useEffect callback will not have access to
// the result of any changes that the setInterval callback makes to the state of
// a component in a previous 'tick' of the interval.

// This hook circumvents that issue by redefining the callback inside the setInterval
// after every tick (using the state created by the previous tick).  This happens
// without the interval being interupted.
import React, { useRef, useEffect } from 'react';
export default function useInterval(callback, intervalSeconds, rerunVars=[]) {
  const savedCallback = useRef();

  // Called after every render ensuring each tick of the interval runs the callback
  // in the context of the state values at the moment it runs.
  useEffect(() => {
    savedCallback.current = callback;
  });

  // Called after first render only
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }

    let id = setInterval(tick, intervalSeconds);
    return () => clearInterval(id);
  }, rerunVars);
}