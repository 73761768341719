import React, { useState, useRef } from 'react';
import useFetch from 'hooks/useFetch';
import lwAjax from 'utilities/lwAjax';
import moment from 'moment';

import DatePicker from 'react-datepicker';

function AdminMenu(props) {

  const {
    page,
    quizzes
  } = props;

  const [admin, setAdmin] = useState({});
  const [menuStyle, setMenuStyle] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  // Initialize export date range to last full month
  let thisMonthStart, lastMonthStart, lastMonthEnd;
  function initializeExportRange() {
    thisMonthStart = moment().date(1).hour(0).minute(0).second(0).millisecond(0);
    lastMonthStart = moment(thisMonthStart).month(moment(thisMonthStart.month()-1));
    lastMonthEnd = moment(thisMonthStart).milliseconds(-1);
  }
  initializeExportRange();

  const [startDate, setStartDate] = useState(new Date(lastMonthStart));
  const [endDate, setEndDate] = useState(new Date(lastMonthEnd));
  const onChange = dates => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const [quizToExport, setQuizToExport] = useState('allQuizzes')

  const menuRef = useRef();

  const [exporter, setExporter] = useState('');

  function zPad(val) {
    return (val < 10 ? '0' + val : val)
  }

  // Check if the user is already signed in as an Admin
  useFetch('GET', '/admin.json', handleCurrentAdmin);

  function handleCurrentAdmin(responseData) {
    console.log('responseData current admin: ', responseData);
    setAdmin(responseData);
  }

  function adminSignOut() {
    lwAjax('GET', "/admins/sign_out.json", handleAdminSignOutSuccess).catch((error) => {
      console.error('adminSignOut error:', error);
    });
  }

  function handleAdminSignOutSuccess(data) {
    console.log('Successful signout response:', data);
    setAdmin(data);
    window.location.href='/admins/sign_in'
  }

  function toggleMenu() {
    setMenuStyle(menuVisible => !menuVisible);
    if (menuStyle) {
      // re-initialize dates when opening menu
      initializeExportRange();
      setStartDate(new Date(lastMonthStart));
      setEndDate(new Date(lastMonthEnd));
    } else {
      // Collapse the export when closing the menu
      if (exporter) {
        toggleExport();
      }
    }
  }

  function toggleExport() {
    setExporter(wasOpened => !wasOpened);
  }

  function cancelExport() {
    toggleMenu();
  }

  function runExport() {
    let exportType = document.querySelector('.exportType').selectedOptions[0].value;
    let startDateTime = moment(startDate).format();
    let endDateTime = moment(endDate).hour(23).minute(59).second(59).millisecond(999).format();
    let exportURL = '/quiz_exports?type=' + exportType + '&start_time=' + startDateTime + '&end_time=' + endDateTime;
    if (quizToExport != 'allQuizzes') {
      exportURL = `${exportURL}&quiz_id=${quizToExport}`
    }
    console.log(exportURL);
    window.open(exportURL);
    toggleMenu();
  }

  function performExport(){
    window.location.href=exportURL;
  }

  function selectQuizToExport(e) {
    setQuizToExport(e.target.value);
  }

  return (
    <div className="admin-menu">
      <div className="admin-menu-header">
        <span className="admin-menu-icon" alt="Admin Menu" onClick={toggleMenu}>&equiv;</span>
      </div>
      { menuStyle ?
        (
        <div ref={menuRef} className="admin-menu-content">
        <div>
          <ul className="admin-menu-actions">
            { page != 'QuizIndex' ? ( // Don't show "List Quizzes" on the quiz list
              <li onClick={toggleMenu}><a href="/quizzes" target="_blank">List Quizzes</a></li>
            ) : (
              null
            )}
            { page != 'QuizBuilder' ? ( // Don't show "New Quiz" on the new quiz screen
              <li onClick={toggleMenu}><a href="/quizzes/new" target="_blank">New Quiz</a></li>
            ) : (
            null
          )}
            { page == 'QuizIndex' && quizzes && quizzes.length > 0 ? ( // Show "Export" on the quiz list only and if there're quizzes
            <li>
              <div onClick={toggleExport}>{!exporter ? 'Export …' : 'Choose What to Export:'}</div>
              { exporter ?  (
                <div className="exporter">
                  <div className="form">
                    <div className="form-row">
                      <label>Type:</label>
                      <select
                        className="exportType"
                        defaultValue="results"
                      >
                        <option value="traffic">Quiz Traffic</option>
                        <option value="results">Player Results</option>
                        <option value="survey">Survey Answers</option>
                        <option value="preferences">Contact Info & Prefs</option>
                      </select>
                    </div>
                    <div className="form-row">
                      <label>Content:</label>
                      <select defaultValue="allQuizzes" onChange={selectQuizToExport}>
                        <option key={'allQuizzes'} value={'allQuizzes'}>All Quizzes</option>
                        { quizzes.map((quiz) =>
                            <option key={quiz.id} value={quiz.id}>{`ID ${quiz.id} - ${quiz.name}`}</option>
                        )}
                      </select>
                    </div>
                    <div className="form-row date range start">
                      <label>Date Range:
                        <span className="hint">Default: last full month</span>
                      </label>
                      <DatePicker
                        selected={startDate}
                        onChange={onChange}
                        startDate={startDate}
                        endDate={endDate}
                        maxDate={new Date()}
                        selectsRange
                        inline
                      />
                    </div>
                    <div className="form-row">
                      <button className="export" onClick={runExport}>Export</button>
                    <button className="cancel" onClick={cancelExport}>Cancel</button></div>
                  </div>
                </div>
              ) : (
                null
              )}
            </li>) : null }
            <li><a onClick={adminSignOut}>Sign Out {admin.email}</a></li>
          </ul>
        </div>
      </div>
    ) : (
      null
    )}

    </div>
  )
}

export default AdminMenu;
