import React, { useState, useEffect } from 'react';
import useLwAjax from 'hooks/useLwAjax';

function LwJotForm(props) {
  const {
    formData,
    quizId,
    playerId,
    isLastSection,
    transitionToNextSection
  } = props;

  const [nextButtonText] = useState(isLastSection ? 'Finish >' : 'Skip >');
  const [decoratedFormUrl] = useState(buildDecoratedFormUrl());

  function buildDecoratedFormUrl() {
    const answersCorrect = sessionStorage.getItem('answersCorrect');
    const questionCount = sessionStorage.getItem('questionCount');
    const playerTier = sessionStorage.getItem('playerTier');

    let url = `${formData.url}?quiz_id=${quizId}&player_id=${playerId}`;
    url += `&answers_correct=${answersCorrect}&question_count=${questionCount}&player_tier=${playerTier}`;
    return url;
  }

  useEffect(jotFormScript, []);
  function jotFormScript() {

    // The following came from the iframe embed instructions in JotForm admin, but unnecessary
    // elements of the original script have been removed.
    // Tested with "LiveWorld ChatBot Hubspot Integration" form...other forms may trigger other
    // actions/generate different values for local vars, and the original may need to be re-copied in here.
    window.handleIFrameMessage = function(e) {
      // Ensure this message came from the iframe
      if (!['https://form.jotform.com', 'https://submit.jotform.com'].includes(e.origin)){ return; }
      if (typeof e.data === 'object') {
        if (e.data.action === 'submission-completed') {
          return transitionToNextSection();
        } else {
          return;
        }
      }
      // In testing:
        // var args = ["setHeight", "885", "201217777827058"] (last arg is JF id)
      var args = e.data.split(":");
      let iframe;
      if (args.length > 2) {
        // This one always gets hit in testing and sets iframe to the iframe in the template.
        iframe = document.getElementById("JotFormIFrame-" + args[(args.length - 1)]);
      } else {
        // This will always resolve to undefined since even without a formData.id property
        // the id of the iframe will be "JotFormIFrame-"...should probably add a gracefull
        // escape when error handling becomes a thing.
        iframe = document.getElementById("JotFormIFrame");
      }

      if (!iframe) { return; }
      switch (args[0]) {
        case "scrollIntoView":
          iframe.scrollIntoView();
          break;
        case "setHeight":
          iframe.style.height = args[1] + "px";
          break;
        case "collapseErrorPage":
          if (iframe.clientHeight > window.innerHeight) {
            iframe.style.height = window.innerHeight + "px";
          }
          break;
        case "exitFullscreen":
          if      (window.document.exitFullscreen)        window.document.exitFullscreen();
          else if (window.document.mozCancelFullScreen)   window.document.mozCancelFullScreen();
          else if (window.document.mozCancelFullscreen)   window.document.mozCancelFullScreen();
          else if (window.document.webkitExitFullscreen)  window.document.webkitExitFullscreen();
          else if (window.document.msExitFullscreen)      window.document.msExitFullscreen();
          break;
      }
    };

    if (window.addEventListener) {
      window.addEventListener("message", handleIFrameMessage, false);
    } else if (window.attachEvent) {
      window.attachEvent("onmessage", handleIFrameMessage);
    }
  }

  return (
    <div className="jot-form-integration">
      <iframe
        id={`JotFormIFrame-${formData.id}`}
        title={formData.title}
        src={decoratedFormUrl}
      ></iframe>
      <button
        onClick={transitionToNextSection}
        className="jot-form-next-button">
        { nextButtonText }
      </button>
    </div>
  );
}

export default LwJotForm
