import React from 'react';
import QuizSurveyAnswerInputs from './QuizSurveyAnswerInputs';

function QuizSurveyQuestionInputs(props) {
  const {
    currentSurveyQuestionData,
    setCurrentSurveyQuestionData
  } = props;

  function handleQuizQuestionInputChange(e) {
    setCurrentSurveyQuestionData({
      ...currentSurveyQuestionData,
      [e.currentTarget.name]: e.currentTarget.value
    });
  }

  function handleQuestionTypeChange(e) {
    setCurrentSurveyQuestionData({...currentSurveyQuestionData, question_type: e.currentTarget.value});
  }

  function handleQuizAnswerInputChange(e) {
    const targetIndex = +e.currentTarget.id.match(/\d+/)[0];
    // find the answer that changed and modify it in the currentSurveyQuestionAnswers array
    const newAnswersList = currentSurveyQuestionData.answers.map((answer) => {
      if (answer.index === targetIndex) {
        answer.text = e.currentTarget.value;
      }
      return answer;
    })
    setCurrentSurveyQuestionData({...currentSurveyQuestionData, answers: newAnswersList});
  }

  function addAnswer() {
    const nextIndex = currentSurveyQuestionData.answers.length;
    const newAnswersList = [...currentSurveyQuestionData.answers, {text: '', index: nextIndex}];
    setCurrentSurveyQuestionData({...currentSurveyQuestionData, answers: newAnswersList});
  }

  function deleteAnswer(targetIndex) {
    // Not bothering with markForDelete with answers.  Just removing them from the
    // answers array, adjusting the other indices, and letting the backend handle the rest
    const newAnswersList = currentSurveyQuestionData.answers.filter(a => a.index !== targetIndex).map((answer) => {
      if (answer.index > targetIndex) { answer.index-- }
      return answer;
    });
    setCurrentSurveyQuestionData({...currentSurveyQuestionData, answers: newAnswersList});
  }

  return (
    <div className="quiz-survey-question-inputs">
      <div className="current-question-text">
        <label>Question Text</label>
        <textarea
          name="text"
          onChange={handleQuizQuestionInputChange}
          value={currentSurveyQuestionData.text || ''}
        ></textarea>
      </div>

      <div className="current-question-type">
        <label htmlFor="current-question-type">Question Type</label>
        <select id="current-question-type"
                onChange={handleQuestionTypeChange} value={currentSurveyQuestionData.question_type}>
          <option value={'buttons'}>Button List</option>
          <option value={'checkboxes'}>Checkboxes</option>
        </select>
      </div>

      <div className="answers">
        <button className="add-answer" onClick={addAnswer}>Add Answer</button>
      {currentSurveyQuestionData.answers.map((answer) =>
        <div className="answer-list" key={answer.index}>
          <QuizSurveyAnswerInputs
            answer={answer}
            deleteAnswer={deleteAnswer}
            handleQuizAnswerInputChange={handleQuizAnswerInputChange}
          />
        </div>
      )}
      </div>
    </div>
  );
}

export default QuizSurveyQuestionInputs
