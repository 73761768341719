import React, { useState } from 'react';
import SharedMetatagsBuilder from "../../utilities/SharedMetatagsBuilder";

function SharedMetadataInputs(props) {
  const {
    currentSharedMetadata,
    setCurrentSharedMetadata
  } = props;

  const metatagsBuilder = SharedMetatagsBuilder(currentSharedMetadata);

  function updateTextarea() {
    jQuery('#shared-metadata textarea').val(metatagsBuilder.buildSharedMetatags());
  }

  function saveSharedUrl(value) {
    setCurrentSharedMetadata(state => {
      state.shared_url = value;
      return {...state};
    });
    updateTextarea();
  }

  function saveTitle(socialNetwork, value) {
    setCurrentSharedMetadata(state => {
      state[socialNetwork].title = value;
      return {...state};
    });
    updateTextarea();
  }

  function saveDescription(socialNetwork, value) {
    setCurrentSharedMetadata(state => {
      state[socialNetwork].description = value;
      return {...state};
    });
    updateTextarea();
  }

  function saveImageUrl(socialNetwork, value) {
    setCurrentSharedMetadata(state => {
      state[socialNetwork].image_url = value;
      return {...state};
    });
    updateTextarea();
  }

  return (
    <div>
      <fieldset className="sharing-metadata-container sharing-metadata-url">
        <legend>URL to Share:</legend>
        <label>Share URL:</label>
        <input type="text"
               name="currentSharedMetaData.sharedUrl"
               value={currentSharedMetadata.shared_url || ''}
               onChange={(e) => saveSharedUrl(e.target.value)}
               placeholder="If the quiz is embedded in another page, the URL of that page. The page must be secure (https://). No need to enter the quiz's native riddler.liveworld.com url."
        />
        <div className="hint-text">
          <b>Note:</b> Whether the quiz is embedded or not, your Facebook/Twitter metadata will be included when the
          quiz is run directly on riddler.liveworld.com.
        </div>
      </fieldset>

      <fieldset className="sharing-metadata-container sharing-metadata-facebook">
      <legend>Facebook</legend>
        <label>Title:</label>
        <input type="text"
               className={'title'}
               maxLength={40}
               value={currentSharedMetadata.facebook.title || ''}
               onChange={(e) => saveTitle('facebook', e.target.value)}
        />
        <div className="hint-text">Limit: 40 characters.</div>
        <label>Description:</label>
        <input type="text"
               className={'description'}
               maxLength={108} value={currentSharedMetadata.facebook.description || ''}
               onChange={(e) => saveDescription('facebook', e.target.value)}
        />
        <div className="hint-text">Limit: 108 characters.</div>
        <label>Image URL:</label>
        <input type="text"
               className={'url'}
               value={currentSharedMetadata.facebook.image_url || ''}
               onChange={(e) => saveImageUrl('facebook', e.target.value)}
        />
        <div className="hint-text">Can be from Images > Miscellaneous Images above.</div>
        <div className="hint-text">Aspect ratio: 1.90:1 – Size: 600x315px to 1200x630px</div>
      </fieldset>

      <fieldset className="sharing-metadata-container sharing-metadata-twitter">
        <legend>Twitter</legend>
        <label>Title:</label>
        <input type="text"
               className={'title'}
               maxLength={40}
               value={currentSharedMetadata.twitter.title || ''}
               onChange={(e) => saveTitle('twitter', e.target.value)}
        />
        <div className="hint-text">Limit: 40 characters.</div>
        <label>Description:</label>
        <textarea className={'description'}
                  rows={3}
                  cols={100}
                  maxLength={200}
                  value={currentSharedMetadata.twitter.description || ''}
                  onChange={(e) => saveDescription('twitter', e.target.value)}/>
        <div className="hint-text">Limit: 200 characters.</div>
        <label>Image URL:</label>
        <input type="text"
               className={'url'}
               value={currentSharedMetadata.twitter.image_url || ''}
               onChange={(e) => saveImageUrl('twitter', e.target.value)}
        />
        <div className="hint-text">Can be from Images > Miscellaneous Images above.</div>
        <div className="hint-text">Aspect ratio: 1.90:1 – Size: 600x315px to 1200x630px</div>
      </fieldset>

      { currentSharedMetadata.shared_url && currentSharedMetadata.shared_url.length > 0 ? (
        <fieldset id="shared-metadata" className="sharing-metadata-container sharing-metadata-result">
          <legend>Sharing Metadata</legend>
          <textarea readOnly={true} value={metatagsBuilder.buildSharedMetatags()}></textarea>
          <div className={'hint-text'}>
            <b>Note:</b> After saving the quiz (and, if embedded adding metadata to the host page), test and pre-cache
            with
            the <a href={`https://developers.facebook.com/tools/debug/?q=${currentSharedMetadata.shared_url}`}
                   target="_blank">
            Facebook Sharing Debugger
          </a> and <a href="https://cards-dev.twitter.com/validator" target="_blank">
            Twitter Validator
          </a>.

          </div>
        </fieldset>) : null
      }
    </div>
  )
}

export default SharedMetadataInputs
