import React, { useState } from 'react';
import useInterval from 'hooks/useInterval';

function InactivityCountdown(props) {
  const {
    setFinalInactivityCountdownInitiated,
    initInactivityTimeout,
    quizId
  } = props;
  const [displayedTime, setDisplayedTime] = useState("1:00");
  const [secondsRemaining, setSecondsRemaining] = useState(60);

  useInterval(handleSecondElapsed, 1000);

  function handleSecondElapsed() {
    if (secondsRemaining > 0) {
      const newSecondsRemaining = secondsRemaining - 1
      setSecondsRemaining(newSecondsRemaining);
      const paddedSeconds = newSecondsRemaining < 10 ? `0${newSecondsRemaining}` : newSecondsRemaining;
      setDisplayedTime(`0:${paddedSeconds}`);
    } else {
      resetGameState();
    }
  }

  function resetGameState() {
    window.location.href = `/players/sign_out?quiz_id=${quizId}`;
  }

  function cancelCountdown() {
    setFinalInactivityCountdownInitiated(false);
    initInactivityTimeout();
  }

  return (
    <div className="flex inactivity-countdown">
      <div className="inactivity-backdrop"></div>
      <div className="flex inactivity-countdown-content">
        <div className="inactivity-countdown-text">
          <div>
            Are you<br />still there?
          </div>
        </div>
        <div className="inactivity-countdown-clock">
          { displayedTime }
        </div>
        <div className="inactivity-countdown-actions">
          <div className="inactivity-countdown-action">
            <button className="inactivity-button" onClick={cancelCountdown}>CONTINUE</button>
          </div>
          <div className="inactivity-countdown-action">
            <button className="inactivity-button" onClick={resetGameState}>QUIT</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default InactivityCountdown
