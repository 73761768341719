// Take a raw error { username: ["has already been taken"] } & return a helpful error
export default function useHelpfulErrors(rawErrors) {
  // Initially set the raw error empty so this will return a default error message
  // in case we can't parse a helpful error
  let rawErrorMessage = '';
  const errors = Object.entries(rawErrors);

  // errors will look like this: [ [ 'username', [ 'has already been taken' ] ] ]
  // We're only going to display the first error as our forms are pretty basic at this point
  if (errors[0] && errors[0][0] && errors[0][1]) {
    // Set the raw message to "key value"
    rawErrorMessage = `${errors[0][0]} ${errors[0][1][0]}`;
  }

  return helpfulError(rawErrorMessage);
}

function helpfulError(rawErrorMessage) {
  // Build this out with common errors
  if (rawErrorMessage == 'username has already been taken') {
    return 'Sorry, that player name has already been taken. Please choose a different name.'
  } else if (rawErrorMessage == 'email is invalid') {
    return 'Please enter a valid email address (or leave it empty).'
  } else if (rawErrorMessage == 'email has already been taken') {
    return 'Sorry, that email is already in use. Please try another.'
  } else if (rawErrorMessage == 'Unable to create preferences') {
    return 'There was an error updating your player. Please see attendant.'
  }

  return "There was an error creating your player. Please see attendant.";
}
