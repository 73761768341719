import React, { useEffect } from 'react';
import useFetch from 'hooks/useFetch';
import ahoy from "ahoy.js";

function CustomGetStartedScreen(props) {
  const {
    transitionToNextSection,
    customHTML,
    recordEvent
  } = props;

  useEffect(() => {
    document.getElementsByTagName('button')[0].addEventListener('click', transitionToNextSection);
    recordEvent('custom_get_started_screen_viewed');
  }, []);


  return (
    <div className="custom-get-started-wrapper" dangerouslySetInnerHTML={{__html: customHTML}} />
  );
}

export default CustomGetStartedScreen
