import React from 'react';

function QuizPlayerLevelInputs(props) {

  const {
    currentPlayerLevel,
    setCurrentPlayerLevel
  } = props;

  // TODO: add some validation that lower, upper inputs are numeric
  function handlePlayerLevelChange(e) {
    setCurrentPlayerLevel({
      ...currentPlayerLevel,
      [e.currentTarget.name]: e.currentTarget.value
    })
  }

  return (
    <div className="player-level-builder">
      <div className="input-group">
        <label>Tier Name:
          <input
            type="text"
            name="level_name"
            onChange={handlePlayerLevelChange}
            value={currentPlayerLevel.level_name || ''}
          />
        </label>
        <label>Score Range:</label>
        <label>from
          <input
            type="text"
            name="lower"
            onChange={handlePlayerLevelChange}
            value={currentPlayerLevel.lower || ''}
          />
        </label>
        <label>to
          <input
            type="text"
            name="upper"
            onChange={handlePlayerLevelChange}
            value={currentPlayerLevel.upper || ''}
          />
        </label>
        <label>Survey Header
          <input
            type="text"
            name="survey_level_header"
            onChange={handlePlayerLevelChange}
            value={currentPlayerLevel.survey_level_header || ''}
          />
        </label>
      </div>
    </div>
  );
}

export default QuizPlayerLevelInputs
