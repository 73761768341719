import React, { useState } from 'react';
import SurveyAnswer from './SurveyAnswer';
import useFetch from 'hooks/useFetch';
import lwAjax from 'utilities/lwAjax';

function Survey(props) {
  const {
    activeQuiz,
    activePlayer,
    transitionToNextSection,
    isLastSection
  } = props;

  const [scoreHeader, setScoreHeader] = useState('');
  const [surveyLevelHeader, setSurveyLevelHeader] = useState('');
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [currentSurveyQuestion, setCurrentSurveyQuestion] = useState({answers: []});
  const [nextButtonText, setNextButtonText] = useState('Next >');
  const [nextButtonClass, setNextButtonClass] = useState('no-answer-selected');
  const [selectedAnswerIds, setSelectedAnswerIds] = useState([]);

  useFetch('GET', `/${activeQuiz.obfuscated_id}/players/${activePlayer.id}/level.json`, handlePlayerLevelData);
  useFetch('GET', `/${activeQuiz.obfuscated_id}/players/${activePlayer.id}/score.json`, initScoreHeader);
  useFetch('GET', `/quizzes/${activeQuiz.id}/quiz_survey_questions.json`, initQuizSurveyQuestions);

  function initScoreHeader(data) {
    setScoreHeader(`Well done! You got ${data.correct_response_count} of ${data.total_response_count} questions correct!`);
  }

  function handlePlayerLevelData(data) {
    if (!data.error) {
      setSurveyLevelHeader(data.survey_level_header);
    }
  }

  function initQuizSurveyQuestions(data) {
    const orderedQuestions = data.sort((a, b) => a.index - b.index );
    setSurveyQuestions(orderedQuestions);
    setCurrentSurveyQuestion(orderedQuestions[0]);
    if (orderedQuestions.length === 1 && isLastSection) {
      // When there's only one survey question ...
      setNextButtonText('Finish >');
    }
  }

  function handleClickNext(e) {
    if (nextButtonClass === 'no-answer-selected') {
      // Display "You must select an answer..." message or something...
    } else {
      submitSurveyResponse(selectedAnswerIds);

      const wasFinalQuestion = currentSurveyQuestion.index === surveyQuestions.length - 1;
      if (wasFinalQuestion) {
        transitionToNextSection();
      } else {
        // Transition to next question & set the button text if the next question is the final question
        const nextIndex = currentSurveyQuestion.index + 1;
        const nextQuestionIsFinal = nextIndex === surveyQuestions.length - 1;

        setCurrentSurveyQuestion(surveyQuestions[nextIndex]);
        setNextButtonClass('no-answer-selected');

        if (nextQuestionIsFinal && isLastSection) {
          setNextButtonText('Finish >');
        }
      }
      setSelectedAnswerIds([]);
    }
  }

  function handleAnswerSelect(e) {
    const id = parseInt(e.target.id);
    let clonedAnswerIds = JSON.parse(JSON.stringify(selectedAnswerIds));

    if (currentSurveyQuestion.question_type === 'checkboxes') {
      const index = clonedAnswerIds.indexOf(id);
      if (index === -1) {
        clonedAnswerIds.push(id);
      } else {
        clonedAnswerIds.splice(index, 1);
      }
    } else if (currentSurveyQuestion.question_type === 'buttons') {
      clonedAnswerIds = [id];
    }

    setSelectedAnswerIds(clonedAnswerIds);

    if (clonedAnswerIds.length > 0) {
      setNextButtonClass('');
    } else {
      setNextButtonClass('no-answer-selected');
    }
  }

  function submitSurveyResponse(surveyAnswerId) {
    const params = {
      quiz_survey_answer_ids: surveyAnswerId,
      quiz_id: activeQuiz.id,
      current_player_id: +sessionStorage.getItem('activePlayerId'),
      session_id: sessionStorage.getItem('sessionId')
    }
    const body = JSON.stringify(params);
    lwAjax('POST', `/${activeQuiz.obfuscated_id}/quiz_survey_responses.json`, handleSurveyResponseCreate, body);
  }

  function handleSurveyResponseCreate(data) {
    console.log("Success saving response", data);
  }

  return (
    <div className="flex survey">
      <div className="flex survey-content">
        <div className="survey-header">
          {scoreHeader != "" ? <div className="score-header">{scoreHeader}</div> : null }
          {surveyLevelHeader != "" ? <div className="level-header">{surveyLevelHeader}</div> : null }
        </div>
        <div className="survey-body">
          <div className="survey-question">
            { currentSurveyQuestion.text }
          </div>
          <div className={`survey-answers ${currentSurveyQuestion.question_type}`}>
          {currentSurveyQuestion.answers.sort((a, b) => a.index - b.index ).map((answer) =>
            <SurveyAnswer
              key={answer.id}
              answer={answer}
              handleAnswerSelect={handleAnswerSelect}
              isSelected={selectedAnswerIds.includes(answer.id)}
              questionType={currentSurveyQuestion.question_type}
            />
          )}
          </div>
        </div>
        <div className="survey-footer">
          <button onClick={handleClickNext} className={`survey-submit-button ${nextButtonClass}`}>{nextButtonText}</button>
        </div>
      </div>
    </div>
  );
}

export default Survey
