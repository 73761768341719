import React, { useEffect } from 'react';
import lwAjax from 'utilities/lwAjax';

export default function useFetch(method, url, success, triggers=[]) {
  useEffect(() => {
    const fetchData = () => {
      lwAjax(method, url, success).catch((error) => {
        console.log("Error retrieving data", error);
      });
    };

    fetchData();
  }, triggers);
}
