import lwAjax from 'utilities/lwAjax';
import { IgnoredProperties } from 'utilities/constants';

export default function QuizQuestionsAndOptionsService(initialQuestionList) {

  function saveData(preSubmitQuestionList, quizId) {
    const questionsToSubmit = preSubmitQuestionList.filter(isNewModifiedOrMarkedForDelete);
    const promises = questionsToSubmit.map((question) => {
      const action = isNew(question) ? 'POST' : question.markedForDelete ? 'DELETE' : 'PUT';
      const questionId = question.id === `unsaved-${question.index}` ? '' : question.id || '';
      if(questionId === '') { question.id = null }
      const body = JSON.stringify({question: question});
      return lwAjax(action, `/quizzes/${quizId}/quiz_questions/${questionId}`, handleCreateOrUpdate, body);
    });
    return Promise.all(promises);
  }

  // ------------------------ PRIVATE START ------------------------
  function handleCreateOrUpdate(data) {
    // handle resolution logic in caller
    return data;
  }

  function isNewModifiedOrMarkedForDelete(question) {
    return isNew(question) || question.markedForDelete || isQuestionModified(question);
  }

  function isNew(question) { return !question.id || question.id === `unsaved-${question.index}`; }

  function isQuestionModified(question) {
    const targetQuestionInitialValue = initialQuestionList.find(q => q.id === question.id);
    // Iterate over every key/value pair in the initial copy of the question stored when the service initialized
    // and each key/value pair in both objects in the nested options array.  Compare these with the key/value pairs in
    // the passed in question at the time of submit, and return true if there are any differences.
    return !Object.entries(targetQuestionInitialValue).every(([questionProperty, initialValue]) => {
      if (IgnoredProperties.includes(questionProperty)) { return true; }
      if (questionProperty === 'options') {
        return areOptionsEqual(question.options, initialValue);
      } else {
        return question[questionProperty] === initialValue;
      }
    });
  }

  function areOptionsEqual(currentOptions, initialOptions) {
    if (currentOptions.length !== initialOptions.length) { return false }
    // Iterate over both options in the state they will be submitted in and compare against
    // the equivalent option (by 'id') in the initial options array.  If either option
    // has changed in any way, we mark the question for update.
    return currentOptions.every((currentOption) => {
      const initial = initialOptions.find(o => o.id === currentOption.id);
      if (!initial) { return false } //option has been added
      return Object.entries(currentOption).every(([optionProperty, currentValue]) => {
        if (IgnoredProperties.includes(optionProperty)) { return true; }
        return initial[optionProperty] === currentValue;
      });
    });
  }
  // ------------------------ PRIVATE END ------------------------

  return {
    saveData
  }
}
