import React, { useState, useEffect } from 'react';
import useFetch from 'hooks/useFetch';

function FinalResults(props) {
  const {
    quiz,
    playerId
  } = props;

  const [thankYouHeaderTwo, setThankYouHeaderTwo] = useState('');
  const [thankYouHeaderThree, setThankYouHeaderThree] = useState('');

  useFetch('GET', `/${quiz.obfuscated_id}/players/${playerId}/final_results_data.json`, handleQuizScoreData);

  // BAD_DATA_HACK
  // Currently the ask is to assume the quiz questions are present and generating score data (even though
  // we have no validations to support this), but to allow the user to create a running quiz in the absence
  // of player levels, which are also optional.
  function handleQuizScoreData(data) {
    if (!data.error) {
      const pluralized = data.quiz_score.correct_response_count === 1 ? "question" : "questions";
      const headerTwo = `You answered ${data.quiz_score.correct_response_count} ${pluralized} correctly.`;
      setThankYouHeaderTwo(headerTwo);

      setThankYouHeaderThree((data.level_data || {}).survey_level_header);
    }
  }

  return (
    <div className="final-results">
      <div className="final-results-header">
        <div className="final-results-header-one">
          Thank You!
        </div>
        <div className="final-results-header-two">
          { thankYouHeaderTwo }
        </div>
        <div className="final-results-header-three">
          { thankYouHeaderThree }
        </div>
      </div>
    </div>
  );
}

export default FinalResults
