import React, { useState, useEffect } from 'react';
import useHelpfulErrors from 'hooks/useHelpfulErrors';
import lwAjax from 'utilities/lwAjax';
import useFetch from 'hooks/useFetch';

function GetStartedScreen(props) {
  const {
    transitionToNextSection,
    setActivePlayer,
    setPlayerCreated,
    recordEvent
  } = props;

  const [playerName, setPlayerName] = useState("");
  const [playerCreateError, setPlayerCreateError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [playerNameSubmitted, setPlayerNameSubmitted] = useState("");

  // Any logged in Player should have been logged out on the AttractScreen,
  // but there was an issue on the 3rd run thru where the Player wasn't logged out & I'm not sure why,
  // so calling it again
  // Ensure no Player is still logged in
  useFetch('GET', '/players/sign_out.json');

  useEffect(() => { recordEvent('get_started_screen_viewed') }, []);

  function createNewPlayer() {
    if (playerNameSubmitted) {
      return;
    }
    // { "player" => { "display_name" => "Hi I am a Cool dude" } }
    setPlayerCreateError(false);

    const body = JSON.stringify({ player: { display_name: playerName } });
    setPlayerNameSubmitted(true);
    lwAjax('POST', "/players.json", handlePlayerCreation, body).catch((error) => {
      // If we get here these are going to be network errors/local JS errors & not HTTP errors
      console.error('Error creating Player:', error);
      setPlayerNameSubmitted(false);
      setPlayerCreateError(true);
      setErrorMessage(error);
    });
  }

  function handlePlayerCreation(data) {
    const errors = data.errors;
    if (errors) {
      // Dig out any errors from Player creation
      // {"errors":{"username":["has already been taken"]}}
      setPlayerNameSubmitted(false);
      setPlayerCreateError(true);
      setErrorMessage(useHelpfulErrors(errors));
    } else {
      // Player was created successfully, proceed to Quiz
      setActivePlayer(data);
      setPlayerCreated(true);
      transitionToNextSection();
    }
  }

  function handleSubmit(e) {
    e.preventDefault();
    createNewPlayer();
  }

  function handleChange(e) {
    setPlayerName(e.target.value);
  }

  return (
    <div className="flex get-started-screen">
      <div className="flex get-started-screen-content">
        <div className="prompt">
          <div>Let's Get Started!</div>
        </div>

        <div className="player-name-form">
          {playerCreateError ? <div className="error-message">{`${errorMessage}`}</div> : null }
          <form onSubmit={handleSubmit}>
            <div className="player-name-input-column">
              <div className="player-name-text instructions">
                Enter Your Player Name:
              </div>
              <input className="player-name-input" type="text" name="playerName" placeholder="Player XX" value={playerName} onChange={handleChange} />
              <div className="player-name-text player-name-examples">(Examples: SmartyPants, Quizmaster, etc.)</div>
            </div>
            <div className="player-name-submit-column">
              <button type="submit" className="player-name-submit">Play!</button>
            </div>
          </form>
        </div>

        <div className="disclaimer">
          <div>Your user name will appear with your results on the leaderboard.</div>
          <br/>
          <div>We reserve the right to remove inappropriate usernames.</div>
        </div>
      </div>
    </div>
  );
}

export default GetStartedScreen
