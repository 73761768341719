import React, { useState, useEffect } from 'react';
import formatTime from "utilities/formatTime"
import useActionCable from "hooks/useActionCable"

function Leaderboard(props) {
  const {
    initialQuizScores,
    quizId,
    customCssFiles,
    quizLeaderboardBackgroundUrl
  } = props;
  const [quizScores, setQuizScores] = useState(initialQuizScores);
  const [leaderboardBackgroundStyles, setLeaderboardBackgroundStyles] = useState({});

  useActionCable({
    channel: "QuizScoresChannel",
    quiz_id: quizId
  }, recQuizScoreChannelData);

  function recQuizScoreChannelData(data) {
    if (data.action == 'score_added') {
      addScore(data.quiz_score);
    } else if (data.action == 'score_removed') {
      removeScore(data.quiz_score);
    } else if (data.action == 'score_updated') {
      updateScore(data.quiz_score);
    }
  }

  useEffect(loadBackgroundImage, []);
  function loadBackgroundImage() {
    if (quizLeaderboardBackgroundUrl) {
      const bgi = new Image();
      bgi.src = quizLeaderboardBackgroundUrl;
      bgi.onload = function() {
        setLeaderboardBackgroundStyles({
          backgroundImage: `url(${quizLeaderboardBackgroundUrl})`
        });
      };
    } else {
      setLeaderboardBackgroundStyles({
        background: 'grey'
      });
    }
  }

  function updateScore(score) {
    const updatedScores = quizScores.map(qs => qs.id === score.id ? score : qs );
    setQuizScores(updatedScores);
  }

  function addScore(score) {
    const unsortedScores = [...quizScores, score];
    const sortedScores = unsortedScores.sort((a, b) => {
      const responseCountComparison = b.correct_response_count - a.correct_response_count;
      if (responseCountComparison == 0) {
        return a.elapsed_time - b.elapsed_time;
      } else {
        return responseCountComparison;
      }
    });
    const trimmedScores = sortedScores.slice(0, 10);
    setQuizScores(trimmedScores);
  }

  function removeScore(score) {
    const filteredScores = quizScores.filter(qs => qs.id !== score.id);
    setQuizScores(filteredScores);
  }

  // TODO: This was copied here from QuizGameRunner.jsx and modified: Make it DRY if necessary
  //
  // onLoadedCssMapUpdate will run when loadedCssMap is initialized and then every time one of the
  // CSS files' onload function runs and updates the loadedCssMap for that file's id.  When
  // the last one is set to true the conditional in onLoadedCssMapUpdate will return true and the
  // game will be initialized with the first section.
  useEffect(onLoadedCssMapUpdate, [loadedCssMap]);
  const [loadedCssMap, setLoadedCssMap] = useState({});
  useEffect(loadCustomCss, []);
  function loadCustomCss() {
    if (customCssFiles.length) {
      // initialize loadedCss as a map of custom_css_file ids
      // and a boolean to indicate whether they've been loaded or not
      setLoadedCssMap(customCssFiles.reduce((acc, file) => {
        acc[file.id] = false;
        return acc;
      }, {}));
      // Create a stylesheet link tag for every active custom css file associated with this quiz
      // and set its onload function to update the loadedCssMap when that file is loaded.
      customCssFiles.sort((fileA, fileB) => fileA.id - fileB.id).forEach((file) => {
        let cssLink = document.createElement("link");
        cssLink.rel = 'stylesheet';
        cssLink.type = 'text/css';
        cssLink.href = file.url;
        cssLink.onload = function() {
          setLoadedCssMap({...loadedCssMap, [file.id]: true});
        }
        document.getElementsByTagName('head')[0].appendChild(cssLink);
      });
    }
  }

  function onLoadedCssMapUpdate() {
    const allCssLoaded = Object.entries(loadedCssMap).every((cssFileId, cssLoaded) => cssLoaded);
  }

  return (
    <div className="leaderboard quiz-image-background" style={leaderboardBackgroundStyles}>
      <div className="leaderboard-header">
        <div>
          LiveWorld Quiz Leaderboard
        </div>
      </div>
      <table className="leaderboard-table">
        <thead className="light-text">
          <tr>
            <th>&nbsp;</th>
            <th>Name</th>
            <th>Score</th>
            <th>Time</th>
          </tr>
        </thead>
        <tbody>
        {quizScores.map((quizScore,row) =>
          <tr key={quizScore.id}>
            <td className="row_num">{row+1}</td>
            <td className="player_name truncate-overflow" title={quizScore.display_name + (quizScore.username != quizScore.display_name.toLowerCase() ? ' (' + quizScore.username + ')' : '')}>{(quizScore.display_name.length > 35 ? quizScore.display_name.substring(0,34) + '…' : quizScore.display_name)}</td>
            <td className="player_score">{quizScore.correct_response_count}/{quizScore.total_response_count}</td>
            <td className="elapsed_time">{formatTime(quizScore.elapsed_time)}</td>
          </tr>
        )}
        </tbody>
      </table>
      <div className="leaderboard-footer">
        <div className="heavy-text">Test your knowledge</div>
        <div className="light-text">Try the quiz</div>
        <div className="light-text">Take the lead</div>
      </div>
    </div>
  );
}

export default Leaderboard
