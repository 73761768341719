import React, { useState } from 'react';
import CustomFinalResultsHtmlGenerator from 'utilities/CustomFinalResultsHtmlGenerator';

function CustomFinalResultsInputs(props) {
  const {
    savedCustomFinalResultsHtml,
    currentCustomFinalResultsHtml,
    setCurrentCustomFinalResultsHtml
  } = props;

  const [tokenList] = useState(() => {
    return [
      "[:PLAYER_LEVEL_NAME]",
      "[:PLAYER_LEVEL_HEADER]",
      "[:PLAYER_NAME]",
      "[:TOTAL_QUESTION_COUNT]",
      "[:CORRECT_ANSWER_COUNT]",
      "[:ANSWER_WEIGHT_SUM]",
      "[:ANSWER_WEIGHT_MAX]",
      "[:UTM_PARAMS]",
      "[:UTM_SOURCE]",
      "[:UTM_MEDIUM]",
      "[:UTM_CAMPAIGN]",
      "[:UTM_CONTENT]",
      "[:UTM_TERM]"
    ].join("\n");
  });

  const [expandedHintState] = useState({
    labelHint: 'Copy and paste tokens from the list below into the HTML area.',
    caretDirection: 'down',
    textAreaClass: 'visible',
    type: 'expanded'
  });

  const [minimizedHintState] = useState({
    labelHint: 'Toggle open to view and copy custom HTML template tokens.',
    caretDirection: 'right',
    textAreaClass: 'hidden',
    type: 'minimized'
  });

  const [currentHintState, setCurrentHintState] = useState(expandedHintState);

  function toggleTokenBox() {
    const newState = currentHintState.type === 'expanded' ? minimizedHintState : expandedHintState;
    setCurrentHintState(newState);
  }

  function resetToDefault() {
    if(window.confirm('Reset Custom Final Results HTML to Default?')) {
      const defaultHtml = new CustomFinalResultsHtmlGenerator('default').generate();
      setCurrentCustomFinalResultsHtml(defaultHtml);
    }
  }

  function resetToSaved() {
    if(window.confirm('Reset Custom Final Results HTML to Saved?')) {
      setCurrentCustomFinalResultsHtml(savedCustomFinalResultsHtml);
    }
  }

  return (
    <div className="custom-final-results-inputs">
      <div className="cfr-input-wrapper">
        <div className="cfr-text-input-wrapper">
          <div className="label-and-input-hints">
            <label>Custom Page HTML:</label>
            <div className="input-hints">
              <div>Required.</div>
              <div>No length limit.</div>
              <div>Must be valid HTML.</div>
              <div>To enable click tracking, use 'class="tracked-link"'</div>
            </div>
          </div>
          <textarea
            onChange={(e) => setCurrentCustomFinalResultsHtml(e.currentTarget.value)}
            value={currentCustomFinalResultsHtml || ''}
          ></textarea>
        </div>
        <div className="cfr-reset-buttons">
          <button onClick={resetToDefault}>
            Reset to Default
          </button>
          <button onClick={resetToSaved}>
            Reset to Saved
          </button>
        </div>
      </div>
      <div className="cfr-hints-wrapper">
        <div className="cfr-hints-toggle">
          <i
            className={`fas fa-caret-${currentHintState.caretDirection}`}
            onClick={toggleTokenBox}
          ></i>
          <label>Template tokens:</label>
          <div className="cfr-hint-detail">{currentHintState.labelHint}</div>
        </div>
        <textarea
          readOnly={true}
          className={currentHintState.textAreaClass}
          value={tokenList}
        ></textarea>
      </div>
    </div>
  );
}

export default CustomFinalResultsInputs
