export default function lwAjax(method, url, success, body=false) {
  let reqOptions = {
    method: method,
    headers: {
      'Content-Type': 'application/json'
    }
  }
  if (body) {
    reqOptions = Object.assign(reqOptions, {body: body});
    if (typeof body === 'object') {
      // setting content type to json for FormData submissions (such as image uploads)
      // breaks the request.  This header is set automatically in such instances.
      delete reqOptions.headers;
    }
  }
  function preProcessResponse(response) {
    // Handle 204 No Content which we get from Devise
    if (response.status == 204) {
      return "";
    } else if (!response.ok) {
      return new Promise((resolve, reject) => {
        return reject(response);
      });
    } else {
      return response.json();
    }
  }
  function error(response) {
    console.log("response --> ", response);
    return new Promise((resolve, reject) => {
      return reject(response);
    });
  }
  // Return before the .catch block has been invoked so that the caller can implement that separately.
  // window.fetch only calls that block on network failure anyway, which until we come up with a plan for
  // site wide error handling with some kind of user notification, we can just rely on the console/network tab.
  return fetch(url, reqOptions)
    .then(preProcessResponse)
    .then(success, error);
}
