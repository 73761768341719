import React, { useEffect } from 'react';
import lwAjax from 'utilities/lwAjax';

// This accepts an array of config objects as the first arg with the same properties as
// useLwAjax (except triggers and delay; see note below), and executes the requests in
// the order they appear in the array, but not until the promise generated by the previous
// request resolves.

// The second arg is an object of config properties that will be used for all calls. Currently only
// triggers, and delay are supported.
// The triggers arg is applied to the top level useEffect hook ensuring that all calls associated with
// the passed in config objects are run when one of them is modified (unless the onlyRunIf fn returns false).
// The delay option could be improved...currently it is only and always applied before each call.  Down the road
// it might be usefull to expand this so that the delay can be applied between calls or applied only
// before specific ones.
export default function useLwAjaxChain(fetchConfigs=[], chainConfig={}) {
  const {
    triggers=[],
    delay=0
  } = chainConfig;

  useEffect(() => {

    const makeRequests = () => {
      asyncForEach(fetchConfigs, async (config) => {
        const {
          method,
          url,
          success,
          body,
          onlyRunIf=()=>{return true;}
        } = config;

        if (!onlyRunIf()) {
          return;
        }

        try {
          await resolveAfterMS(delay);
          await lwAjax(method, url, success, body);
        } catch(e) {
          handleError(e);
        }
      });
    };

    makeRequests();
  }, triggers);
}

function handleError(error) {
  console.log("Error retrieving data", error);
}

function resolveAfterMS(ms) {
  return new Promise(resolve => {
    setTimeout(() => {
      resolve();
    }, ms);
  });
}

// Thank you Sebastien Chopin!
// https://codeburst.io/javascript-async-await-with-foreach-b6ba62bbf404
async function asyncForEach(array, callback) {
  for (let index = 0; index < array.length; index++) {
    await callback(array[index], index, array);
  }
}